import request from "../utils/request";
import { encrypt } from "@/utils/crypto.js";
import { apiBase, apiDelivery, apiFleet } from "@/utils/env-global";
export function login(data) {
  return request({
    url: `${apiBase}/public/signin`,
    method: "POST",
    data: {
      payload: encrypt(data),
    },
  });
}
export function checkFeatureLTL() {
  return request({
    url: `${apiFleet}/v2/pstx-serv/contract-unit-parameter/shp/check`,
    method: "GET",
  });
}
export function register(payload) {
  return request({
    url: `${apiBase}/v2/usmx-serv/auth/signup`,
    method: "POST",
    data: {
      payload: encrypt(payload),
    },
  });
}

export function checkAccount(payload) {
  return request({
    url: `${apiBase}/public/account-check`,
    method: "POST",
    data: payload,
  });
}

export function submitOtp(payload) {
  return request({
    url: `${apiBase}/v2/usmx-serv/auth/otp-verification`,
    method: "POST",
    data: payload,
  });
}

export function resendOtp(payload) {
  return request({
    url: `${apiBase}/v2/usmx-serv/auth/otp-resend`,
    method: "POST",
    data: payload,
  });
}

// api for verification otp by existing account
export function sendOtpExistingAccount(payload) {
  return request({
    url: `${apiBase}/v2/usmx-serv/auth/otp-request`,
    method: "POST",
    data: payload,
  });
}

export function changeEmailExistingAccount(payload) {
  return request({
    url: `${apiBase}/v2/usmx-serv/account/change-email`,
    method: "POST",
    data: payload,
  });
}

export function submitOtpEmailExistingAccount(payload) {
  return request({
    url: `${apiBase}/v2/usmx-serv/account/change-email`,
    method: "PATCH",
    data: payload,
  });
}
// end otp existing account

export function forgotPassword(payload) {
  return request({
    url: `${apiBase}/public/forgotpassword`,
    method: "POST",
    data: {
      payload: encrypt(payload),
    },
  });
}

export function resetPassword(payload) {
  return request({
    url: `${apiBase}/public/updatepassword`,
    method: "POST",
    data: {
      payload: encrypt(payload),
    },
  });
}

export function logout() {
  return request({
    url: `${apiBase}/v2/usmx-serv/account/signout`,
    method: "POST",
    data: {
      source: "web",
    },
  });
}

export function getInfo(token) {
  if (!token) {
    throw new Error("No token");
  }
  return request({
    url: `${apiBase}/public/userrole`,
    method: "GET",
    headers: { Authorization: "Bearer " + token },
  });
}

export function changeBranch(payload) {
  return request({
    url: `${apiDelivery}/api-auth/v1/change-branch`,
    method: "POST",
    data: payload,
  });
}

export function getAuthorization(token) {
  if (!token) {
    throw new Error("No token");
  }
  return request({
    url: `${apiBase}/account/authorization`,
    method: "GET",
    headers: { Authorization: "Bearer " + token },
  });
}

export function reqSwitchApp() {
  return request({
    url: `${apiBase}/v2/usmx-serv/account/change-version`,
    method: "GET",
  });
}

export function switchApp(payload) {
  return request({
    url: `${apiBase}/v2/usmx-serv/account/change-version`,
    method: "GET",
    params: payload,
  });
}

export function getProfile() {
  return request({
    url: `${apiBase}/v2/usmx-serv/account/personal`,
    method: "GET",
  });
}

export function updateProfile(payload) {
  return request({
    url: `${apiBase}/v2/usmx-serv/account/personal`,
    method: "PUT",
    data: payload,
  });
}

export function refreshToken() {
  return request({
    url: `${apiBase}/auth/refresh-token`,
    method: "POST",
  });
}

export function getCompany() {
  return request({
    url: `${apiBase}/v2/usmx-serv/account/company`,
    method: "GET",
  });
}

export function updateCompany(payload) {
  return request({
    url: `${apiBase}/v2/usmx-serv/account/company`,
    method: "PUT",
    data: payload,
  });
}

export function setBillingAddress(payload) {
  return request({
    url: `${apiBase}/v2/usmx-serv/user-management/company/billing`,
    data: payload,
    method: "PUT",
  });
}

export function getQuestions() {
  return request({
    url: `${apiBase}/public/questions`,
    method: "GET",
  });
}

export function getCompanyCategories() {
  return request({
    url: `${apiBase}/public/companycategories`,
    method: "GET",
    params: {
      status: true,
    },
  });
}

export function getRoles(payload) {
  return request({
    url: `${apiBase}/usermanagement/get/roles`,
    method: "GET",
    params: payload,
  });
}

export function updateSecurityQuestion(payload) {
  return request({
    url: `${apiBase}/v2/usmx-serv/account/security-question`,
    method: "POST",
    data: payload,
  });
}

export function updatePassword(payload) {
  return request({
    url: `${apiBase}/v2/usmx-serv/account/authentication`,
    method: "PUT",
    data: {
      payload: encrypt(payload),
    },
  });
}

export function removeAccount(payload) {
  return request({
    url: `${apiBase}/v2/usmx-serv/account/request-non-active-account`,
    method: "POST",
    data: payload,
  });
}
