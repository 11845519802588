<template>
  <div class="mb-[0.125rem] block min-h-[1.5rem] pl-[1.5rem]">
    <input
      class="relative disabled:grayscale float-left -ml-[1.5rem] mr-1 mt-0.5 h-5 w-5 appearance-none rounded-full border-2 border-solid border-neutral-300 before:pointer-events-none before:absolute before:h-4 before:w-4 before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:shadow-[0px_0px_0px_13px_transparent] before:content-[''] after:absolute after:z-[1] after:block after:h-4 after:w-4 after:rounded-full after:content-[''] checked:border-primary checked:before:opacity-[0.16] checked:after:absolute checked:after:left-1/2 checked:after:top-1/2 checked:after:h-[0.625rem] checked:after:w-[0.625rem] checked:after:rounded-full checked:after:border-primary checked:after:bg-gradient-to-tr checked:after:from-secondary checked:after:to-primary checked:after:content-[''] checked:after:[transform:translate(-50%,-50%)] hover:cursor-pointer hover:before:opacity-[0.04] hover:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:shadow-none focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] checked:focus:border-primary checked:focus:before:scale-100 checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:border-neutral-600 dark:checked:border-primary dark:checked:after:border-primary dark:checked:after:bg-primary dark:focus:before:shadow-[0px_0px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:border-primary dark:checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca]"
      type="radio"
      :value="props.value"
      :name="props.name"
      :id="`radio${idComp}`"
      v-model="modelRadio"
      @change="onChange"
      :disabled="props.disabled"
    />
    <label
      v-if="props.label"
      class="mt-px inline-block pl-[0.15rem] hover:cursor-pointer"
      :for="`radio${idComp}`"
    >
      {{ props.label }}
    </label>
  </div>
</template>

<script setup>
import { useComponentStore } from "@/stores/component";
import { computed, onMounted, ref, watch } from "vue";

const props = defineProps({
  label: {
    type: String,
    default: "",
  },
  name: {
    type: String,
    default: "radiobutton1",
  },
  value: {
    type: [String, Boolean, Number],
    default: "value",
  },
  modelValue: {
    type: [String, Number],
    default: "",
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});
const modelRadio = ref("");
const emit = defineEmits(["update:modelValue", "change"]);
const onChange = (event) => {
  emit("update:modelValue", event.target.value);
  emit("change", event.target.value);
};
const useComponent = useComponentStore();
useComponent.setUid();
const idComp = ref(useComponent.uid);
const compModel = computed(() => {
  return props.modelValue;
});
watch(compModel, (val) => {
  if (val == props.value) {
    modelRadio.value = val;
  }
});
onMounted(() => {
  if (compModel.value == props.value) {
    modelRadio.value = props.value;
  }
});
</script>
