<template>
  <div v-if="confirmData.show">
    <overlay-layer persistent z-index="z-[99]" />
    <div
      :class="confirmData.options.width"
      :key="`${confirmData.show}-show`"
      class="fixed z-[100] top-1/2 left-1/2 w-full -translate-x-1/2 -translate-y-1/2"
    >
      <div
        v-if="confirmData.options && confirmData.options.style == 'kindle'"
        class="p-6 rounded-2xl bg-white drop-shadow-2xl"
      >
        <div class="text-xl text-black-3 font-bold mb-4 w-full">
          {{
            confirmData.options.i18n ? $t(confirmData.title) : confirmData.title
          }}
        </div>
        <div
          class="mb-5 font-medium text-sm"
          v-html="
            confirmData.options.i18n ? $t(confirmData.desc) : confirmData.desc
          "
        ></div>
        <div v-if="confirmData.options.reason" class="mb-9">
          <field-text-area
            :placeholder="$t('m.general.confirm.reason')"
            v-model="reason"
          ></field-text-area>
        </div>
        <div
          v-if="confirmData.options.check"
          class="flex gap-2 mb-6 items-center -mt-1"
        >
          <check-box v-model="checked" />
          <div class="text-sm font-medium">
            {{ $t("m.general.confirm.check") }}
          </div>
        </div>
        <div class="flex justify-end gap-4">
          <base-button
            classes="min-w-[80px]"
            outlined
            @click.stop="actionConfirm(false)"
          >
            {{ $t(confirmData.options.label.no) }}
          </base-button>
          <base-button
            classes="min-w-[80px]"
            @click.stop="actionConfirm(true)"
            :disabled="
              (confirmData.options.reason == true &&
                required(reason) !== true) ||
              (confirmData.options.check && checked == false)
            "
          >
            {{ $t(confirmData.options.label.yes) }}
          </base-button>
        </div>
      </div>
      <div v-else class="p-6 rounded-2xl bg-white drop-shadow-2xl">
        <div
          class="text-base text-primary font-semibold pb-1 w-full border-b border-gray-4"
        >
          {{
            confirmData.options.i18n ? $t(confirmData.title) : confirmData.title
          }}
        </div>
        <div
          class="py-9 font-normal text-sm"
          v-html="
            confirmData.options.i18n ? $t(confirmData.desc) : confirmData.desc
          "
        ></div>
        <div v-if="confirmData.options.reason" class="-mt-6 mb-9">
          <field-text-area
            :placeholder="$t('m.general.confirm.reason')"
            v-model="reason"
          ></field-text-area>
        </div>
        <div
          v-if="confirmData.options.check"
          class="flex gap-2 mb-6 items-center -mt-1"
        >
          <check-box v-model="checked" />
          <div class="text-sm font-medium">
            {{ $t("m.general.confirm.check") }}
          </div>
        </div>
        <div class="flex justify-end gap-[10px]">
          <base-button outlined @click.stop="actionConfirm(false)">
            {{
              confirmData.options.i18n
                ? $t(confirmData.options.label.no)
                : confirmData.options.label.no
            }}
          </base-button>
          <base-button
            @click.stop="actionConfirm(true)"
            :disabled="
              (confirmData.options.reason == true &&
                required(reason) !== true) ||
              (confirmData.options.check && checked == false)
            "
          >
            {{
              confirmData.options.i18n
                ? $t(confirmData.options.label.yes)
                : confirmData.options.label.yes
            }}
          </base-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useComponentStore } from "@/stores/component";
import { computed, ref, watch } from "vue";
import { required } from "@/utils/custom-validator";
import CheckBox from "@/components/elements/CheckBox.vue";
const componentStore = useComponentStore();
const confirmData = computed(() => {
  return componentStore.confirm;
});
const checked = ref(false);
const reason = ref("");
const actionConfirm = (status) => {
  componentStore.actionConfirm(status, reason.value);
  reason.value = "";
};
watch(
  () => confirmData.value.show,
  (val) => {
    if (val) {
      checked.value = false;
    }
  }
);
</script>
