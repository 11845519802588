export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["test"])},
  "validations_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["validations is language for vuelidate support."])},
  "validations": {
    "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field is required."])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email is not valid, exp: name", "@", "email.com"])},
    "minLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The field must have a min length of ", _interpolate(_named("min")), "."])},
    "maxLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The field must have a max length of ", _interpolate(_named("max")), "."])},
    "sameAsPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password did not match"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The password must contain numbers, lowercase letters, uppercase letters and also symbols ", "(#?!@$%^&*-)"])},
    "requiredNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Field must be filled in more than zero"])},
    "requiredNumberDecimal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Field must be filled in more than zero"])},
    "validNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The field is only filled with numbers"])},
    "validPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the phone number starting with 0 (zero)"])}
  },
  "el_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["el is language for every element components."])},
  "el": {
    "switch_view": {
      "grid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grid"])},
      "row": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Row"])}
    },
    "select_check": {
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select all"])},
      "selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected"])},
      "no_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No data"])}
    },
    "list_checkbox": {
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select All"])},
      "selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected"])}
    },
    "file_input": {
      "warning_type": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The attachment format is not supported, upload the attachment with the format ", _interpolate(_named("msg"))])},
      "warning_size": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Maximum attachment size is ", _interpolate(_named("msg")), "Mb"])},
      "warning_amount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The maximum attachment is ", _interpolate(_named("msg")), " fileee"])}
    }
  },
  "modules_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["m is language for every modules components."])},
  "m": {
    "filter": {
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply Filter"])},
      "labels": {
        "delivery_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery Date"])},
        "invoice_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice Date"])}
      },
      "filter_status": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping Status"])}
      },
      "filter_payment_status": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Status"])}
      },
      "filter_overdue": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overdue"])}
      },
      "filter_delivery_type": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery Type"])}
      },
      "filter_truck": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Truck Type"])}
      },
      "filter_sub_truck": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sub Truck Type"])}
      },
      "filter_load": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Load Type"])}
      },
      "filter_invoice_category": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice Category"])}
      },
      "filter_billing_category": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billing"])}
      },
      "filter_goods_status": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])}
      },
      "filter_notif_status": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])}
      }
    },
    "header_auth": {
      "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
      "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])}
    },
    "footer_auth": {
      "version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VERSION"])},
      "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download Mologiz App"])}
    },
    "header_main": {
      "account": {
        "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
        "sign_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign Out"])}
      },
      "notification": {
        "not_read": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haven't Read"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Notifications"])},
        "show_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show All"])},
        "order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order"])},
        "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No notification has entered.Immediately make orders and get related orders."])}
      }
    },
    "calendar": {
      "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply"])}
    },
    "card": {
      "card_address": {
        "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])}
      },
      "card_address_item": {
        "goods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goods"])},
        "amount_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sum Item"])},
        "name_sender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sender Full Name"])},
        "name_receiver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receiver Full Name"])},
        "phone_sender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sender Phone Number"])},
        "phone_receiver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receiver Phone Number"])},
        "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note"])},
        "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full Address"])}
      },
      "card_date_note": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date and Note"])},
        "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
        "full_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full Address"])},
        "pickup_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pickup Time"])},
        "no_ref": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reference Number"])},
        "fleet_completeness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fleet Completeness"])},
        "standard_completeness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standard Completeness"])},
        "specific_completeness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specific Completeness"])},
        "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note"])}
      },
      "card_service_empty": {
        "soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soon"])}
      },
      "card_shipment": {
        "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From"])},
        "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To"])},
        "reorder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reorder"])},
        "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Detail"])}
      },
      "card_shipment_redirect": {
        "info_favorite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add your favorite order from the order history menu"])},
        "info_shipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add orders to the menu for the order"])},
        "order_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order History"])},
        "create_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order"])}
      },
      "card_contract": {
        "origin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Origin"])},
        "destination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destination"])},
        "truck": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Truck"])},
        "rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
        "order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order"])}
      },
      "card_contract_redirect": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The contract is not yet available."])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please contact Mologiz to place an order."])}
      }
    },
    "services": {
      "FTL": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FTL"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order vehicles per unit"])},
        "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vehicle booking features per unit.By using this feature your goods will not be mixed with other sender."])}
      },
      "LTL": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LTL"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Trucking & Courier content"])},
        "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partial ordering features.You will save more costs by sharing places with other sender."])}
      },
      "FCL": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FCL"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Container message"])},
        "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vehicle booking features per container.By using this feature your goods will not be mixed with other sender."])}
      },
      "LCL": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LCL"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send a content with containers"])},
        "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partial ordering features with containers."])}
      }
    },
    "table": {
      "no_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No data"])}
    },
    "general": {
      "confirm": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation"])},
        "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input Reason"])},
        "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
        "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])}
      },
      "countdown": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fleet Search"])},
        "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Day"])},
        "hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hour"])},
        "minute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minute"])},
        "second": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Second"])}
      },
      "list_text": {
        "no_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Data"])}
      },
      "modal_idle": {
        "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The system detects you not doing activity for 1 hour.You will exit the application automatically in time :"])}
      }
    },
    "order": {
      "completeness": {
        "standard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standard Completeness"])},
        "specific": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specific Completeness"])}
      },
      "upload_goods": {
        "list_goods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List Goods"])},
        "add_goods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Goods"])},
        "edit_goods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Goods"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation"])},
        "confirm_save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save goods data ?"])},
        "confirm_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit goods data ?"])},
        "name_goods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name Goods"])},
        "select_goods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose the goods"])},
        "serial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No. Serial"])},
        "input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input"])},
        "length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Length"])},
        "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Width"])},
        "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Height"])},
        "cubication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cubication"])},
        "vol_weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volume Weight"])},
        "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
        "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
        "empty_goods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is no goods data yet. Add goods or upload goods in bulk"])},
        "upload_bulk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload Goods Bulky"])},
        "upload_excel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload Excel"])},
        "download_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download Templates"])},
        "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
        "invalid_value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid value"])},
        "table": {
          "headers": {
            "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
            "no_serial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Serial"])},
            "name_goods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name Goods"])},
            "qty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qty"])},
            "lwh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LxWxH(cm)"])},
            "cubication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cubication (m<sup>3</sup>)"])},
            "vol_metric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metric Volume Weight (kg)"])},
            "max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MAX( GW VS VW)"])},
            "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])}
          },
          "actions": {
            "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
            "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])}
          }
        },
        "information": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information"])},
          "point1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make sure the excel file upload format matches the template provided"])},
          "point2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The value of the amount if not filled or filled in zero will be changed to the default value of 1"])}
        },
        "clear_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clean Data"])},
        "clear_mark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Selected"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])}
      },
      "view_goods": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List Goods"])},
        "outlet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outlet"])},
        "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
        "name_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name - Phone Number"])},
        "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note"])},
        "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
        "table": {
          "headers": {
            "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
            "serial_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No. Serial"])},
            "name_goods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name Goods"])},
            "qty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantity"])},
            "p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["P(Cm)"])},
            "l": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L(Cm)"])},
            "t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["T(Cm)"])},
            "cubic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cubication (m<sup>3</sup>)"])},
            "subtotal_cubic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subtotal Cubication"])},
            "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note"])}
          }
        }
      },
      "change_route": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Address"])},
        "outlet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outlet"])},
        "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
        "new_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Full Address"])},
        "placeholder_new_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input New Full Address"])},
        "reason_change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reason for Replacement"])},
        "placeholder_reason_change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input Reason for Replacement"])},
        "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
        "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])}
      },
      "choose_address": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Address"])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
        "no_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No address, please add the address to"])}
      },
      "reasons": {
        "reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reason"])},
        "placeholder_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input the reason"])},
        "other_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other Reason"])},
        "placeholder_other_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input Other Reason"])},
        "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])}
      }
    },
    "crop_image": {
      "image_position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position The Picture"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading"])},
      "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])}
    },
    "preview_file": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preview File"])},
      "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donwload"])},
      "download_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download All"])}
    }
  },
  "layout_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["l is language for every layout."])},
  "l": {
    
  },
  "pages_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This language for every pages."])},
  "dashboard": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
    "filters": {
      "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Period"])},
      "prev_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous Month"])},
      "this_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This Month"])},
      "this_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This Week"])},
      "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Today"])},
      "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom"])}
    },
    "cards": {
      "new_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Order"])},
      "onprogress_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On Progress Orders"])},
      "complete_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order is Complete"])},
      "unpaid_invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unpaid Invoice"])},
      "paid_invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paid Invoice"])},
      "due_date_invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["due Date Invoice"])}
    },
    "chart_donut": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distribution of Delivery Destinations"])},
      "empty_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create more orders to find out your shipping distribution diagram."])}
    },
    "chart_line": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery Amount"])},
      "empty_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create orders to see your shipping comparison diagram."])}
    }
  },
  "login": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login to your mologiz account to get started"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
    "email_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["name", "@", "email.com"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "password_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "remember": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remember Me"])},
    "forgot_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot Password ?"])},
    "login_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login Now"])}
  },
  "register": {
    "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])},
    "account_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill in your personal data to start creating a mologue account"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
    "email_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["name", "@", "email.com"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "password_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "confirm_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation Password"])},
    "confirm_password_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation Password"])},
    "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register As"])},
    "role_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose Role"])},
    "personal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal Data"])},
    "personal_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill in personal data.The name will be displayed on your profile."])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full Name"])},
    "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Security Questions"])},
    "question_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose Security questions"])},
    "answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Answer"])},
    "answer_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Answers from security questions"])},
    "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Data Information"])},
    "company_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company data will be used as a billing address"])},
    "company_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Name"])},
    "company_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PT Your Company"])},
    "pic_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PIC Name"])},
    "pic_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your full Name"])},
    "pic_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cellular pic"])},
    "pic_number_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cellular pic"])},
    "company_npwp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company NPWP"])},
    "company_npwp_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company NPWP Number"])},
    "company_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Email"])},
    "company_email_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contact", "@", "email.com"])},
    "company_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telephone Company"])},
    "company_phone_area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Area Code"])},
    "company_phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone Number"])},
    "company_npwp_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Address"])},
    "company_npwp_address_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company address according to the NPWP"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
    "prev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Account"])}
  },
  "forgot_password": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot Password"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We will send a security code to rearrange your sandals."])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
    "email_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["name", "@", "email.com"])},
    "answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Answer"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send E-mail"])}
  },
  "reset_password": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset Password"])},
    "security_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Security Code"])},
    "security_code_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Security Code"])},
    "new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Password"])},
    "new_password_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type Your New Password"])},
    "new_password_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm The New Password"])},
    "new_password_confirm_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type Your New Password"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Password"])}
  },
  "reset_password_success": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successful Request"])},
    "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Your request to rearrange successful passwords. Please check periodically inbox <b>", _interpolate(_named("email")), "</b> because we will send a security code link to the e-mail."])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I Understand"])}
  },
  "order_create": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Order"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose service"])},
    "favourite": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Favorite Order"])},
      "option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Display Option"])}
    },
    "table": {
      "headers": {
        "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
        "delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery"])},
        "typeTruck": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Truck Type"])},
        "load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Load"])},
        "origin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Origin"])},
        "destination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destination"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])}
      },
      "actions": {
        "reorder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reorder"])}
      }
    }
  },
  "order_history": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order History"])},
    "favorite_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Favorite Order"])},
    "table": {
      "headers": {
        "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
        "shipmentNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No. Shipment"])},
        "etd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ETD"])},
        "shipmentType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
        "originAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Origin"])},
        "originOutlet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outlet Origin"])},
        "destinationAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destination"])},
        "destinationOutlet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outlet Destination"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])}
      },
      "actions": {
        "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Detail"])}
      }
    }
  },
  "order_ftl": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Order FTL"])},
    "search_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
    "option_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Display Option"])},
    "table": {
      "headers": {
        "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
        "originOutlet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Origin Outlet"])},
        "originAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Origin Address"])},
        "destinationOutlet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destination Outlet"])},
        "destinationAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destination address"])},
        "typeTruck": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type - Sub Type Truck"])},
        "load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type of Load"])},
        "rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])}
      },
      "actions": {
        "order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order"])}
      }
    }
  },
  "order_ftl_form": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Order FTL"])},
    "information": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping Information"])},
      "shipper_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipper Name"])},
      "no_contract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No. Contract"])},
      "truck_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Truck Type"])},
      "load_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Load Type"])},
      "backhaul_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backhaul Price"])},
      "regular_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regular Price"])}
    },
    "nav": {
      "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
      "prev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous"])},
      "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order"])}
    },
    "tab": {
      "tab1": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Origin Address, Destination and load of products"])},
        "origin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Origin"])},
        "destination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destination"])},
        "outlet_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outlet Name"])},
        "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note"])},
        "fullname_sender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full Name Sender"])},
        "fullname_recipient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full Name Recipient"])},
        "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cellphone"])},
        "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complete Address"])},
        "address_sender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complete Address Sender"])},
        "address_recipient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complete Address Recipient"])},
        "input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input"])}
      },
      "tab2": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery Time and Additional Information"])},
        "date_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date & Time of Delivery"])},
        "date_time_is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery will be carried out on:"])},
        "set_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Determine the date"])},
        "set_time_delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery"])},
        "oclock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O'clock"])},
        "additional_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional Information"])},
        "amount_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount Order"])},
        "no_ref": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No. Reference"])},
        "no_ref_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The reference number can be filled with the AJU, container, barcode and other supporting document numbers"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File Document"])},
        "file_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please upload so, do, PO or other shipping support documents with a maximum size of 5MB"])},
        "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note"])},
        "input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input"])}
      },
      "tab3": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Review"])},
        "origin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Origin"])},
        "destination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destination"])},
        "shipper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipper"])},
        "cubic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cubication"])},
        "total_goods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Goods"])}
      }
    },
    "table": {
      "headers": {
        "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
        "originOutlet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Origin Outlet"])},
        "originAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destination Address"])},
        "destinationOutlet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destination Outlet"])},
        "destinationAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destination Address"])},
        "typeTruck": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type - Sub Type Truck"])},
        "load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Load Type"])},
        "rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])}
      },
      "actions": {
        "order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order"])},
        "create_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create order?"])},
        "order_without_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to upload document attachments, orders remain received without appendix"])}
      }
    }
  },
  "order_fcl": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Order FCL"])}
  },
  "order_fcl_form": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Order FCL"])}
  },
  "order_ltl_form": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Order LTL"])}
  },
  "order_detail": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipment Detail"])},
    "cancel_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel order"])},
    "delivery_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery History"])},
    "info_detail": {
      "no_shipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No. Shipment"])},
      "etd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery date"])},
      "truck_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Truck Type"])},
      "truck": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Truck (On Call)"])},
      "load_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Load Type"])},
      "no_ref": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reference number"])},
      "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attachment of documents"])},
      "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notes"])}
    },
    "rute": {
      "origin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Origin Address"])},
      "destination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destination Address"])}
    }
  },
  "not_found": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page Not Found!"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Don't worry, check your URL to make sure there are no errors in writing links.<br /> You can also return to Home to proceed to the web MOLOGIZ."])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])}
  },
  "master_address": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Master Address"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
    "table": {
      "actions": {
        "switch": {
          "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
          "nonActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NonActive"])}
        },
        "change_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Status?"])},
        "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail"])},
        "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
        "delete_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Address?"])}
      },
      "headers": {
        "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
        "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full Address"])},
        "outlet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outlet"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
        "phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone Number"])},
        "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])}
      }
    },
    "form": {
      "detail_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Details"])},
      "add_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Address"])},
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "ph_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input name"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone Number"])},
      "ph_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input phone number"])},
      "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note"])},
      "ph_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input note"])},
      "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full Address"])},
      "ph_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input full address"])},
      "outlet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outlet"])},
      "ph_outlet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input outlet"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
      "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
      "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])}
    }
  },
  "contract": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FTL Contract"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["download"])},
    "table": {
      "headers": {
        "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
        "contract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contract"])},
        "origin_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Origin Address"])},
        "destination_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Origin Destination"])},
        "fleet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Truck"])},
        "load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Load Type"])},
        "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
        "addcost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Costs"])},
        "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])}
      },
      "actions": {
        "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detil"])}
      },
      "content": {
        "shipper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipper"])},
        "no_contract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No. Contract"])},
        "outlet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outlet"])},
        "province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Province"])},
        "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
        "district": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["District"])},
        "overnight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overnight"])},
        "multipickup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multipickup"])},
        "multidrop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multidrop"])}
      }
    },
    "no_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Data"])}
  },
  "contract_detail": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contract Details"])},
    "no_contract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No. Contract"])},
    "shipper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipper"])},
    "delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery Type"])},
    "fleet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Truck Type"])},
    "load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Load Type"])},
    "info_rute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Route Information"])},
    "table": {
      "headers": {
        "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
        "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
        "price_return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return Price"])},
        "price_backhaul": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backhaul Price"])},
        "overnight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inap"])},
        "multipickup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multipickup"])},
        "multidrop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multidrop"])},
        "start_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Period"])},
        "end_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End Priode"])},
        "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note"])}
      }
    },
    "province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Province"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
    "district": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["District"])},
    "price_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price List"])}
  },
  "goods": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Master Goods"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
    "delete_mark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Mark"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
    "switch": {
      "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
      "nonActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NonActive"])}
    },
    "form": {
      "detail_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Details"])},
      "add_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Goods"])},
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
      "no_serial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Serial"])},
      "ph_no_serial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input no serial"])},
      "name_goods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name Goods"])},
      "ph_name_goods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input name goods"])},
      "p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Length"])},
      "ph_p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input length"])},
      "l": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Width"])},
      "ph_l": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input width"])},
      "t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Height"])},
      "ph_t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input Height"])},
      "cubic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cubication"])},
      "vol_metric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metric Volume Weight"])},
      "max_load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MAX (GW VS VW)"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
      "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
      "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
      "edit_goods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Goods?"])},
      "add_goods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Goods?"])}
    },
    "table": {
      "actions": {
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Data"])},
        "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload Bulk"])},
        "change_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Status?"])},
        "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detil"])},
        "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
        "deleted": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Delete ", _interpolate(_named("msg")), "?"])},
        "delete_goods": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Delete Goods ", _interpolate(_named("msg")), "?"])},
        "delete_marks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete the item being marked?"])}
      },
      "headers": {
        "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
        "no_serial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Serial"])},
        "name_goods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name Goods"])},
        "p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Length (Cm)"])},
        "l": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Width (Cm)"])},
        "t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Height (Cm)"])},
        "cubic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cubication (m<sup>3</sup>)"])},
        "vol_metric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metric Volume Weight"])},
        "max_load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MAX (GW VS VW)"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
        "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])}
      }
    }
  },
  "finance": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bill History"])},
    "warning_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment status cannot be empty"])},
    "table": {
      "actions": {
        "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View"])},
        "chip": {
          "paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PAID"])},
          "partial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PARTIAL"])},
          "unpaid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UNPAID"])}
        }
      },
      "headers": {
        "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
        "invoice_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No. Invoice"])},
        "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billing"])},
        "invoice_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice Date"])},
        "due_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Due Date"])},
        "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
        "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])}
      }
    }
  },
  "finance_detail": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bill Details"])},
    "invoice": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Invoice ", _interpolate(_named("msg"))])},
    "telp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telp"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "npwp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NPWP"])},
    "dear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dear To"])},
    "invoice_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No. Invoice"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Method"])},
    "terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms"])},
    "due_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Due Date"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "updated_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated By"])},
    "transfer_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer to:"])},
    "no_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A.C (Account Number)"])},
    "name_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A.N (Account Name)"])},
    "total_delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Shipping Costs"])},
    "ppn": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ppn ", _interpolate(_named("msg"))])},
    "total_invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Bill"])},
    "total_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Pay"])},
    "invoice_left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rest of the bill"])},
    "sign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signature of the authorized official."])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
    "download_factur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download Factur"])},
    "download_invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download Invoice"])},
    "table": {
      "headers": {
        "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
        "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
        "shipment_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipment Date"])},
        "rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])}
      }
    }
  },
  "notification": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notification"])},
    "alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The notification will be deleted automatically if more than 7 days"])},
    "filter_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archive"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
    "mark_read": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark as read"])},
    "change_item": {
      "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want archived notification?"])},
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want deleted notification?"])},
      "read": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want mark as read notification?"])}
    },
    "change_items": {
      "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archive the selected notification?"])},
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete the selected notification?"])},
      "read": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark as read the selected notification?"])}
    },
    "open_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open Link"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is no notification at this time. <br /> Immediately create an order and get a notification of related orders."])}
  },
  "profile": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
    "actions": {
      "confirm_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save changes?"])},
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
      "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])}
    },
    "billing_address": {
      "main_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main Address"])},
      "create_main_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create the main address"])}
    },
    "tab": {
      "account": {
        "private_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal Data"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full Name"])},
        "ph_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input full name"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
        "ph_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input email"])},
        "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])},
        "ph_username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input Username"])},
        "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role"])},
        "ph_role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input role access"])},
        "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone Number"])},
        "ph_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input phone number"])}
      },
      "billing": {
        "billing_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billing Data"])},
        "account_bank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Bank Account"])},
        "bank_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank Name"])},
        "no_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No. Account"])},
        "name_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama Account"])},
        "ph_name_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input name account"])},
        "document_completeness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billing Document Equipment"])},
        "cover_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cover Account Bank Book Cover"])},
        "no_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No File"])},
        "ready_upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ready to Upload"])},
        "edit_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit File"])},
        "upload_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload File"])},
        "billing_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billing Address"])},
        "no_billing_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is no billing address"])},
        "create_main_billing_address": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Make ", _interpolate(_named("msg")), " the main address of the billing?"])},
        "success_billing_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully made the main address"])}
      },
      "company": {
        "company_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Data"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Name"])},
        "ph_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input company name"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Email"])},
        "ph_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input company email"])},
        "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Telephone"])},
        "ph_phone_area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])},
        "ph_phone_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input no telephone"])},
        "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Address"])},
        "ph_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input company address"])},
        "company_completeness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Completeness"])},
        "npwp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company NPWP"])},
        "ph_npwp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input company npwp"])},
        "nib": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company NIB"])},
        "ph_nib": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input NIB"])},
        "document_completeness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document Equipment"])},
        "logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Logo"])},
        "ready_upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ready to Upload"])},
        "edit_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit File"])},
        "upload_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload File"])},
        "no_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No File"])}
      },
      "notification": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notification"])},
        "order_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Status"])},
        "payment_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Status"])},
        "new_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Order"])},
        "received_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orders are accepted"])},
        "to_destination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Towards the original location"])},
        "load_goods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Load"])},
        "complete_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order is complete"])},
        "create_new_invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Making new bills"])},
        "over_due": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The bill is due"])},
        "invoice_paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paid bill"])}
      },
      "security": {
        "security_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account Security"])},
        "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Security Questions"])},
        "ph_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input security question"])},
        "answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Answer"])},
        "ph_answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input answer"])},
        "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
        "current_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Password"])},
        "new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Password"])},
        "confirm_new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm New Password"])},
        "change_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change the password?"])}
      }
    },
    "menu": {
      "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal Data"])},
      "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Data"])},
      "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billing Data"])},
      "security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account Security"])},
      "notification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notification"])},
      "avatar_changed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avatar was successfully changed"])}
    },
    "table": {
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
      "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat"])},
      "notification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notification"])}
    }
  },
  "revision_history": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revision History"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])},
    "no_data_download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No data for download"])},
    "success_download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["success for download data"])},
    "table": {
      "headers": {
        "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
        "invoice_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No. Invoice"])},
        "billing_company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billing Company"])},
        "invoice_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice Date"])},
        "invoice_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bill Category"])},
        "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Bill"])},
        "factur_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No. Factur"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])}
      },
      "headers_expand": {
        "invoice_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No. Invoice"])},
        "invoice_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice Date"])},
        "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated Date"])},
        "updated_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated By"])},
        "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note"])}
      }
    }
  },
  "live_tracking": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Live Tracking"])},
    "updated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Updated on ", _interpolate(_named("msg"))])},
    "shipment_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No. Shipment"])},
    "shipment_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipment Date"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
    "truck_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Truck Type"])},
    "sub_truck_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sub Truck Type"])},
    "origin_route": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Origin Route"])},
    "destination_route": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destination Route"])},
    "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy"])},
    "police_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No. Police"])},
    "driver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Driver"])},
    "driver_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone Number Driver"])},
    "success_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery was copied"])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading"])},
    "nothing_shipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There no shipment"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
    "list_shipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List Shipment"])},
    "filter": {
      "active_shipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active Shipment"])},
      "latest_shipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latest Shipment"])},
      "longest_shipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Longest Shipment"])},
      "latest_etd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latest ETD"])},
      "longest_etd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Longest ETD"])}
    }
  },
  "list_staff": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List Staff"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
    "table": {
      "actions": {
        "switch": {
          "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
          "nonActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NonActive"])}
        },
        "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Detail"])},
        "change_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Status?"])}
      },
      "headers": {
        "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
        "full_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full Name"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
        "access_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level Akses"])},
        "phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone Number"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])}
      }
    },
    "form": {
      "detail_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Details"])},
      "add_staff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Staff"])},
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
      "full_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full Name"])},
      "ph_full_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input Full Name"])},
      "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])},
      "ph_username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input Username"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
      "ph_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input email"])},
      "phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone Number"])},
      "ph_phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input phone number"])},
      "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role"])},
      "ph_role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input role access"])},
      "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
      "ph_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input full address"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
      "ph_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input password"])},
      "confirm_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation Password"])},
      "ph_confirm_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input Confirmation Password"])},
      "security_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Security Question"])},
      "ph_security_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input security question"])},
      "answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Answer"])},
      "ph_answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input answer"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
      "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
      "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
      "added_staff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Staff?"])},
      "change_staff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Staff?"])}
    }
  },
  "routes_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This language for every routes."])},
  "r_auth": {
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])},
    "forgot_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot Password"])},
    "forgot_password_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The request forgotten the password was successful"])},
    "reset_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset Password"])}
  },
  "r_order": {
    "transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Order"])},
    "create_ftl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Order FTL"])},
    "create_ftl_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Order FTL"])},
    "create_fcl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Order FCL"])},
    "create_fcl_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Order FCL"])},
    "create_ltl_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Order LTL"])},
    "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["History Shipment"])},
    "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Shipment"])}
  },
  "r_dashboard": {
    "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])}
  },
  "r_corporate": {
    "corporate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corporate"])},
    "master_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Master Address"])},
    "contract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contract"])},
    "contract_FTL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FTL Contract"])},
    "contract_detail_FTL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FTL Contract Details"])},
    "goods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List Goods"])}
  },
  "r_finance": {
    "finance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finance"])},
    "finance_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bill history"])},
    "finance_detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bill Details"])}
  },
  "r_notfound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page Not Found"])},
  "r_notification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notification"])},
  "r_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
  "r_report": {
    "report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report"])},
    "ar_invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AR Bill"])},
    "revision_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revision history"])}
  },
  "r_live_tracking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Live Tracking"])},
  "r_users": {
    "list_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List Users"])},
    "list_staff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List Staff"])}
  },
  "shipment_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is shipment status."])},
  "shipment": {
    "status": {
      "01": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Order"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orders are being processed, waiting for a reply from the transporter"])},
        "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["green"])}
      },
      "02": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orders are replied"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order has been replied by Transporter"])},
        "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["yellow"])}
      },
      "03": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orders are accepted"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orders have been received by the transporter"])},
        "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["green"])}
      },
      "04": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assignment"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transporter has assigned a driver"])},
        "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["yellow"])}
      },
      "05": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Towards the original location"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The driver is heading to the pickup location"])},
        "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["yellow"])}
      },
      "06": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waiting for the goods to fit"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The driver is waiting for the item to be loaded"])},
        "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["yellow"])}
      },
      "07": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Load"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goods are being loaded"])},
        "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["yellow"])}
      },
      "08": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Towards the destination location"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The driver is heading to the destination location"])},
        "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["yellow"])}
      },
      "09": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waiting for unloading goods"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The driver is waiting for the item to be dismantled"])},
        "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["yellow"])}
      },
      "10": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disassemble the goods"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goods are being demolished"])},
        "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["yellow"])}
      },
      "11": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sent"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery of goods has been received by the destination PIC and is still waiting for confirmation of receipts"])},
        "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["green"])}
      },
      "12": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order is complete"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping of goods has been confirmed by Shipper"])},
        "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["green"])}
      },
      "13": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canceled"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orders are canceled"])},
        "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gray"])}
      },
      "14": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COMPLINED"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orders are complained"])},
        "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gray"])}
      },
      "15": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complaints waiting for approval"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complaints tickets have been completed.Tickets must be confirmed"])},
        "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["yellow"])}
      },
      "16": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New order, incomplete"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New order for shipper corporate.The Tower Controller must first complete the detailed address before the transporter updates the shipping update."])},
        "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["green"])}
      },
      "17": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waiting for approval"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transporter has added additional costs, waiting for the approval of the Tower Control"])},
        "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["yellow"])}
      },
      "18": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waiting for verification"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orders with special assignments, need to confirm from superiors."])},
        "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["yellow"])}
      },
      "19": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Driver completed"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This order has been completed by the driver, waiting for additional costs"])},
        "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["green"])}
      },
      "20": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Costs are rejected"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional fees are rejected CT, please fix the data"])},
        "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["yellow"])}
      },
      "21": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to send"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery failed to send due to something"])},
        "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gray"])}
      }
    }
  },
  "category_notif_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is category of notif"])},
  "notif": {
    "category": {
      "01": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice"])},
        "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IconInvoice"])},
        "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["green"])}
      },
      "02": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matching Load"])},
        "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IconTruckFast"])},
        "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["primary"])}
      },
      "03": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Truck Post Offer Unaccepted"])},
        "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IconTruckFast"])},
        "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["primary"])}
      },
      "04": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transporter Order"])},
        "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IconTruckFast"])},
        "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["primary"])}
      },
      "05": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipper Order"])},
        "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IconTruckFast"])},
        "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["primary"])}
      },
      "06": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ditinjau ulang"])},
        "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IconTruckFast"])},
        "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["primary"])}
      },
      "07": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complaint"])},
        "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IconTruckFast"])},
        "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["primary"])}
      },
      "08": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cargo Post Offer"])},
        "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IconTruckFast"])},
        "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["primary"])}
      },
      "09": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Truck Post Offer"])},
        "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IconTruckFast"])},
        "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["primary"])}
      },
      "10": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Truck Post Offer Response"])},
        "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IconTruckFast"])},
        "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["primary"])}
      }
    }
  }
}