import { DashboardLayout, DefaultLayout } from "@/layouts";
const dashboard = [
  {
    path: "/finance",
    component: DashboardLayout,
    meta: {
      title: "r_finance.finance",
      icon: "IconMoney",
      group: "apps",
    },
    single: false,
    hidden: false,
    children: [
      {
        path: "billing-history",
        component: DefaultLayout,
        meta: {
          title: "r_finance.finance_history",
          group: "finance",
        },
        redirect: "/finance/billing-history",
        hidden: false,
        children: [
          {
            path: "",
            name: "finance.billing_history",
            meta: {
              title: "r_finance.finance_history",
              roles: ["shipperfinance:read"],
            },
            hidden: true,
            component: () =>
              import(
                "@/views/shipper/finance/billing-history/BillingHistory.vue"
              ),
          },
          {
            path: "detail/:id",
            name: "finance.detail",
            meta: {
              title: "r_finance.finance_detail",
              roles: ["shipperfinance:read"],
            },
            hidden: true,
            component: () =>
              import(
                "@/views/shipper/finance/billing-detail/BillingDetail.vue"
              ),
          },
          {
            path: "payment",
            name: "finance.payment",
            meta: {
              title: "r_finance.finance_payment",
              roles: ["shipperfinance:read"],
            },
            hidden: true,
            component: () =>
              import("@/views/shipper/finance/payment/PaymentPage.vue"),
          },
          {
            path: "refund",
            name: "finance.refund",
            meta: {
              title: "r_finance.finance_refund",
              roles: ["shipperfinance:read"],
            },
            hidden: true,
            component: () =>
              import("@/views/shipper/finance/refund/RefundPage.vue"),
          },
        ],
      },
    ],
  },
];

export default dashboard;
