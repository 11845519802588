/* eslint-disable prettier/prettier */
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import router from "@/router";
import getPageTitle from "@/utils/get-page-title";
import { publicRoute } from "@/router/config";
import { useUserStore } from "./stores/user";
import { token } from "./utils/crypto-global";
import { useMainStore } from "./stores/main";
// NProgress Configuration
NProgress.configure({ showSpinner: false });

// WHITE LIST ARE ROUTES NOT PERMISSIONS
const whiteList = [];
publicRoute.forEach((el) => {
  if (el.children) {
    el.children.forEach((child) => {
      if (el.path === "/") {
        whiteList.push(el.path + child.path);
      } else {
        whiteList.push(el.path + "/" + child.path);
      }
    });
  } else {
    whiteList.push(el.path);
  }
});

// router guards
router.beforeEach(async (to, from, next) => {
  // define store
  const userStore = useUserStore();
  const mainStore = useMainStore();
  // start progress bar
  NProgress.start();
  // set page title
  document.title = getPageTitle(to.meta.title);
  document.documentElement.setAttribute("lang", mainStore.currentLang);
  // determine whether the user has logged in
  const hasToken = localStorage.getItem(token);
  // checking roles
  const hasRoles = userStore.roles && userStore.roles.length > 0;
  if (hasToken) {
    if (to.path === "/auth/login" && hasRoles) {
      // if is logged in, redirect to the dashboard page
      next({ name: "dashboard" });
      NProgress.done();
    } else {
      // determine whether the user has obtained his permission roles through getInfo
      if (hasRoles) {
        next();
      } else {
        try {
          // get user info
          // note: roles must be a object array! such as: ['admin'] or ,['developer','editor']
          // const data = await userStore.getInfo();
          const data = await userStore.getAuthorization();
          if (!data) {
            localStorage.removeItem(token);
          }
          // generate accessible routes map based on roles
          // await userStore.generateRoutes(data.roles);
          await userStore.generateRoutes(data.permissions);

          // router.push('/')
          // set the replace: true, so the navigation will not leave a history record
          // next("/order");
          next({ ...to, replace: true });
          // router.replace(router.currentRoute.value.fullPath)
        } catch (error) {
          let prevRoute = window.location.pathname;
          // remove token and go to login page to re-login
          localStorage.removeItem(token);
          console.log(error || "Has Error");
          if (prevRoute) {
            next({ path: `/auth/login`, query: { to: prevRoute } });
          } else {
            next(`/auth/login`);
          }
          NProgress.done();
        }
      }
    }
  } else {
    /* has no token*/
    if (whiteList.indexOf(to.path) !== -1) {
      // in the free login whitelist, go directly
      next();
    } else {
      // other pages that do not have permission to access are redirected to the login page.
      let prevRoute = window.location.pathname;
      const urlParams = new URLSearchParams(window.location.search);
      let newParams = [];
      for (const [key, value] of urlParams) {
        newParams.push(`${key}=${value}`);
      }
      newParams = newParams.join("&");

      if (prevRoute) {
        if(newParams) prevRoute = prevRoute + "?" + newParams
        next({ path: `/auth/login`, query: { to: prevRoute } });
      } else {
        next(`/auth/login`);
      }
      NProgress.done();
    }
  }
});

router.afterEach(() => {
  NProgress.done();
});

export default router;
