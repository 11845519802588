import { defineStore } from "pinia";
import { get, show, changeStatus } from "@/api/notification/notification";

export const useNotificationStore = defineStore("notification", {
  state: () => ({
    items: [],
    itemsHeader: [],
    unread: 0,
    total: 0,
  }),
  getters: {},
  actions: {
    clearHeader() {
      this.itemsHeader = [];
      this.unread = 0;
      this.total = 0;
    },
    getHeader() {
      return new Promise((resolve, reject) => {
        get({
          offset: 0,
          limit: 30,
        })
          .then((response) => {
            this.itemsHeader = response.data;
            this.unread = response.unread;
            this.total = response.total;
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    get(payload) {
      return new Promise((resolve, reject) => {
        get(payload)
          .then((response) => {
            this.items = response.data;
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    show(payload) {
      return new Promise((resolve, reject) => {
        show(payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    changeStatus(payload) {
      return new Promise((resolve, reject) => {
        changeStatus(payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
});
