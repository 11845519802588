<template>
  <div class="relative">
    <div class="relative">
      <slot
        name="target"
        :on="{ mouseover: showing, mouseleave: hidden }"
        :attrs="{
          'aria-haspopup': 'true',
          'aria-expanded': show,
        }"
      />
    </div>
    <teleport to="#app">
      <div
        v-if="show"
        :style="objectStyle"
        class="tooltip-content absolute drop-shadow-lg text-xs/[18px] mt-2 z-50 rounded-2xl p-[10px]"
        :class="[props.classes, props.bg, props.font]"
      >
        <slot name="content" />
      </div>
    </teleport>
  </div>
</template>

<script setup>
import { reactive, ref } from "vue";
const props = defineProps({
  width: {
    type: [String, Number],
    default: 200,
  },
  position: {
    type: String,
    default: "center",
    // left, right, center
  },
  classes: {
    type: String,
    default: "",
  },
  bg: {
    type: String,
    default: "bg-primary-dark/95",
  },
  font: {
    type: String,
    default: "text-white",
  },
});
const show = ref(false);
var objectStyle = reactive({});
const showing = (el) => {
  let elem = el.currentTarget.getBoundingClientRect();
  let totalSize = elem.left + parseInt(props.width);
  let widthScreen = window.innerWidth;
  let left = elem.left;
  if (totalSize > widthScreen) {
    left = widthScreen - parseInt(props.width) - 20;
  } else if (parseInt(props.width) > elem.width) {
    left = left - (parseInt(props.width) - elem.width) / 2;
  } else {
    left = left + (elem.width - parseInt(props.width)) / 2;
  }
  if (props.position == "left" && totalSize < widthScreen) {
    left = elem.left - props.width;
  }
  if (props.position == "right" && totalSize < widthScreen) {
    left = elem.left;
  }
  objectStyle = {
    top: `${elem.top + elem.height + window.scrollY}px`,
    left: `${left}px`,
    width: `${props.width}px`,
  };
  show.value = true;
};
const hidden = () => {
  show.value = false;
};
</script>
