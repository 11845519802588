<template>
  <div class="bosy">
    <header-main />
    <div
      :class="sidebar.showSidebar ? 'lg:pl-[245px]' : 'lg:pl-[50px]'"
      class="relative pt-[48px] max-lg:pl-[50px] max-md:pl-[0px] transition-all ease-in-out duration-400"
    >
      <NavigationSidebar />
      <div
        class="mx-auto w-full max-w-[1690px] bg-white"
        style="min-height: calc(100vh - 48px - 89px)"
      >
        <div class="w-full h-full py-4 pl-4 pr-6">
          <router-view />
        </div>
      </div>
      <footer-main />
    </div>
    <modal-select-branch />
    <modal-email-verification />
  </div>
</template>

<script>
import headerMain from "@/components/modules/header/HeaderMain.vue";
import NavigationSidebar from "@/components/modules/navigation/NavigationSidebar/NavigationSidebar.vue";
import { ref } from "vue";
import { useMainStore } from "@/stores/main";
import FooterMain from "@/components/modules/footer/FooterMain.vue";
import ModalSelectBranch from "@/components/modules/general/ModalSelectBranch.vue";
import ModalEmailVerification from "@/components/modules/email-verification/ModalEmailVerification.vue";
export default {
  components: {
    headerMain,
    NavigationSidebar,
    FooterMain,
    ModalSelectBranch,
    ModalEmailVerification,
  },
  setup() {
    const sidebar = useMainStore();
    const nav = ref(false);
    return { nav, sidebar };
  },
};
</script>
