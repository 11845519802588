import { DashboardLayout } from "@/layouts";
const tracking = [
  {
    path: "/tracking",
    component: DashboardLayout,
    meta: {
      title: "r_live_tracking",
      icon: "IconPinAround",
      group: "apps",
    },
    single: true,
    children: [
      {
        path: "",
        name: "tracking.live",
        meta: {
          title: "r_live_tracking",
          roles: ["livetracking:read"],
        },
        hidden: false,
        component: () => import("@/views/shipper/tracking/LiveTracking.vue"),
      },
    ],
  },
];

export default tracking;
