<template>
  <div
    v-on-click-outside="onClickOutsideHandler"
    :class="
      mainStore.showSidebar
        ? 'w-[245px]'
        : 'max-md:w-[0px] max-md:-ml-4 md:w-[50px]'
    "
    class="fixed bg-white pt-2 z-30 transition-all px-1.5 duration-400 ease-in-out left-0 top-[45px] scrollY overflow-hidden overflow-y-auto"
    style="height: calc(100% - 45px)"
  >
    <!-- :class="{ 'hover:drop-shadow-md': listWarehouse.length > 1 }" -->
    <div
      v-if="currentBranch && mainStore.showSidebar"
      @click="showListBranch"
      class="overflow-hidden transition-all cursor-pointer mx-1 mb-2 px-2 py-3 bg-white-1 rounded-md sidebar-branch"
    >
      <h2 class="mb-1 text-xs font-medium">
        {{ $t("m.general.modal_select_branch.branch") }}
      </h2>
      <div class="flex items-center gap-2 pr-3">
        <p class="font-bold text-xs mb-0 line-clamp-1">
          {{
            currentBranch.name
              ? currentBranch.name
              : $t("m.general.modal_select_branch.title")
          }}
        </p>
        <!-- v-if="listWarehouse.length > 1" -->
        <icon-svg
          class="transition-all duration-300"
          :class="{ 'rotate-180': isShowBranch }"
          icon="IconArrowDown"
          :width="10"
          :height="8"
        />
      </div>
    </div>
    <ul class="grid gap-2">
      <li v-for="item in menus" :key="item.name">
        <item-navigation :data="item" />
      </li>
    </ul>
  </div>
</template>

<script setup>
import { useMainStore } from "@/stores/main";
import { computed, onMounted } from "vue";
import ItemNavigation from "./components/ItemNavigation.vue";
import { vOnClickOutside } from "@vueuse/components";
import { useUserStore } from "@/stores/user";
const mainStore = useMainStore();
const userStore = useUserStore();
const onClickOutsideHandler = [
  () => {
    if (mainStore.screenSize == "sm" || mainStore.screenSize == "md") {
      mainStore.setSidebar(false);
    }
  },
  { ignore: ["targetSidebar"] },
];
const menus = computed(() => {
  return userStore.routes.filter((item) => {
    return (
      !item.hidden &&
      item.children &&
      item.children.length > 0 &&
      item.children.some((a) => {
        return (
          (!a.hidden && a.children && a.children.length > 0) ||
          (!a.hidden && Object.keys(a).includes("children") == false)
        );
      })
    );
  });
});
const isShowBranch = computed(() => {
  return userStore.showSelectBranch;
});
const currentBranch = computed(() => {
  return userStore.currentBranch;
});
// const listBranch = computed(() => {
//   return userStore.listBranches;
// });
const showListBranch = () => {
  userStore.setShowSelectBranch(true);
};
onMounted(async () => {
  if (mainStore.screenSize == "sm" || mainStore.screenSize == "md") {
    mainStore.setSidebar(false);
  }
});
</script>
