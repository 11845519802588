// private route
import dashboard from "./dashboard";
import order from "./order";
import finance from "./finance";
import tracking from "./tracking";
import profile from "./profile";
import notification from "./notification";
import corporate from "./corporate";
import users from "./users";
import report from "./report";
import guide from "./guide";
// public route
import auth from "./auth";
import general from "./general";
// import designSystem from "./design-system";
export const protectedRoute = [
  ...dashboard,
  ...tracking,
  ...order,
  ...corporate,
  ...finance,
  ...users,
  ...report,
  ...profile,
  ...notification,
  ...guide,
];
export const publicRoute = [...auth, ...general];
