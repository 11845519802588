export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["test"])},
  "validations_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["validations is language for vuelidate support."])},
  "validations": {
    "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kolom belum diisi."])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email tidak benar, Cth: nama", "@", "email.com"])},
    "minLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["kolom ini minimal ", _interpolate(_named("min")), " karakter."])},
    "maxLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["kolom ini maksimal ", _interpolate(_named("max")), " karakter."])},
    "sameAsPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kata sandi tidak cocok"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kata sandi harus berisi angka, huruf kecil, huruf besar dan juga simbol ", "(#?!@$%^&*-)"])},
    "requiredNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kolom ini harus diisi lebih dari nol"])},
    "requiredNumberDecimal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kolom ini harus diisi lebih dari nol"])},
    "validNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kolom ini hanya diisi angka"])},
    "validPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan no telepon dengan diawali angka 0 (nol)"])}
  },
  "el_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["el is language for every element components."])},
  "el": {
    "switch_view": {
      "grid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grid"])},
      "row": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baris"])}
    },
    "select_check": {
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih Semua"])},
      "selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terpilih"])},
      "no_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak Ada Data"])}
    },
    "list_checkbox": {
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih Semua"])},
      "selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terpilih"])}
    },
    "file_input": {
      "warning_type": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Format lampiran tidak di dukung, unggah lampiran dengan format ", _interpolate(_named("msg"))])},
      "warning_size": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ukuran lampiran maksimal ", _interpolate(_named("msg")), "Mb"])},
      "warning_amount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Maksimal lampiran adalah ", _interpolate(_named("msg")), " File"])}
    }
  },
  "modules_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["m is language for every modules components."])},
  "m": {
    "filter": {
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terapkan Filter"])},
      "labels": {
        "delivery_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal Pengiriman"])},
        "invoice_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal Tagihan"])}
      },
      "filter_status": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status Pengiriman"])}
      },
      "filter_payment_status": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status Pembayaran"])}
      },
      "filter_delivery_type": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipe Pengiriman"])}
      },
      "filter_overdue": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jatuh Tempo"])}
      },
      "filter_truck": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipe Truk"])}
      },
      "filter_sub_truck": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sub Tipe Truk"])}
      },
      "filter_load": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipe Muatan"])}
      },
      "filter_invoice_category": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategori Tagihan"])}
      },
      "filter_billing_category": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Penagihan"])}
      },
      "filter_goods_status": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])}
      },
      "filter_notif_status": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])}
      }
    },
    "header_auth": {
      "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masuk"])},
      "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daftar"])}
    },
    "footer_auth": {
      "version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VERSI"])},
      "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unduh Mologiz App"])}
    },
    "header_main": {
      "account": {
        "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil"])},
        "sign_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keluar"])}
      },
      "notification": {
        "not_read": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belum Baca"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semua Notifikasi"])},
        "show_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tampilkan Semua"])},
        "order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buat Pesanan"])},
        "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belum ada notifikasi yang masuk. Segera buat pesanan dan dapatkan notifikasi pesanan terkait."])}
      }
    },
    "calendar": {
      "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terapkan"])}
    },
    "card": {
      "card_address": {
        "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ubah"])}
      },
      "card_address_item": {
        "goods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Barang"])},
        "amount_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jumlah Item"])},
        "name_sender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama Lengkap Pengirim"])},
        "name_receiver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama Lengkap Penerima"])},
        "phone_sender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nomor Telepon Pengirim"])},
        "phone_receiver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nomor Telepon Penerima"])},
        "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catatan"])},
        "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alamat Lengkap"])}
      },
      "card_date_note": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal dan Catatan"])},
        "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ubah"])},
        "full_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alamat Lengkap"])},
        "pickup_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waktu Penjemputan"])},
        "no_ref": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nomor Referensi"])},
        "fleet_completeness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kelengkapan Armada"])},
        "standard_completeness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kelengkapan Standar"])},
        "specific_completeness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kelengkapan Khusus"])},
        "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catatan"])}
      },
      "card_service_empty": {
        "soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Segera"])}
      },
      "card_shipment": {
        "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dari"])},
        "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menuju"])},
        "reorder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pesan Ulang"])},
        "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lihat Detail"])}
      },
      "card_shipment_redirect": {
        "info_favorite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambahkan pesanan favoritmu dari menu Riwayat Pesanan"])},
        "info_shipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambahkan pesanan pada manu Buat Pesanan"])},
        "order_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riwayat Pesanan"])},
        "create_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buat Pesanan"])}
      },
      "card_contract": {
        "origin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asal"])},
        "destination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tujuan"])},
        "truck": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Armada"])},
        "rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Harga"])},
        "order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pesan"])}
      },
      "card_contract_redirect": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontrak belum tersedia."])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Silahkan hubungi MOLOGIZ untuk melakukan pemesanan."])}
      }
    },
    "services": {
      "FTL": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FTL"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pesan Kendaraan per Unit"])},
        "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fitur pemesanan kendaraan per Unit. Dengan Menggunakan fitur Ini barang anda tidak akan tercampur dengan pengirim lainnya."])}
      },
      "LTL": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LTL"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirim muatan Trucking & Courier"])},
        "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fitur pemesanan sebagian. Anda akan lebih menghemat biaya dengan berbagi tempat dengan pengirim lainnya."])}
      },
      "FCL": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FCL"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pesan Kontainer"])},
        "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fitur pemesanan kendaraan per kontener. Dengan Menggunakan fitur Ini barang anda tidak akan tercampur dengan pengirim lainnya."])}
      },
      "LCL": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LCL"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirim Muatan Dengan Kontainer"])},
        "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fitur pemesanan sebagian dengan kontainer."])}
      }
    },
    "table": {
      "no_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak Ada Data"])}
    },
    "general": {
      "confirm": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfirmasi"])},
        "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan Alasan"])},
        "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ya"])},
        "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak"])}
      },
      "countdown": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pencarian Armada"])},
        "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hari"])},
        "hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jam"])},
        "minute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menit"])},
        "second": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detik"])}
      },
      "list_text": {
        "no_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada data"])}
      },
      "modal_idle": {
        "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sistem mendeteksi Anda tidak melakukan aktivitas selama 1 jam. Anda akan keluar aplikasi secara otomatis dalam waktu :"])}
      }
    },
    "order": {
      "completeness": {
        "standard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kelengkapan Standar"])},
        "specific": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kelengkapan Khusus"])}
      },
      "upload_goods": {
        "list_goods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daftar Barang"])},
        "add_goods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah Barang"])},
        "edit_goods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ubah Barang"])},
        "name_goods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama Barang"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfirmasi"])},
        "confirm_save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simpan data barang ?"])},
        "confirm_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ubah data barang ?"])},
        "select_goods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih barang"])},
        "serial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No. Serial"])},
        "input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan"])},
        "length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panjang"])},
        "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lebar"])},
        "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tinggi"])},
        "cubication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kubikasi"])},
        "vol_weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berat Volume"])},
        "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jumlah"])},
        "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simpan"])},
        "empty_goods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belum ada data barang. Tambah Barang atau Unggah Barang Secara Massal"])},
        "upload_bulk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unggah Massal Barang"])},
        "upload_excel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unggah Excel"])},
        "download_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unduh Template"])},
        "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alamat"])},
        "invalid_value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nilai tidak valid"])},
        "table": {
          "headers": {
            "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
            "no_serial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Serial"])},
            "name_goods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama Barang"])},
            "qty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jumlah"])},
            "lwh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PxLxT(cm)"])},
            "cubication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kubikasi (m<sup>3</sup>)"])},
            "vol_metric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berat Volume Metrik (kg)"])},
            "max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MAX( GW VS VW)"])},
            "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aksi"])}
          },
          "actions": {
            "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ubah"])},
            "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hapus"])}
          }
        },
        "information": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keterangan"])},
          "point1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pastikan format upload file excel sesuai dengan template yang disediakan"])},
          "point2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nilai jumlah jika tidak diisi atau diisi nol maka akan di ubah ke nilai bawaan yaitu 1"])}
        },
        "clear_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bersihkan Data"])},
        "clear_mark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hapus Terpilih"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Batalkan"])}
      },
      "view_goods": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daftar Barang"])},
        "outlet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outlet"])},
        "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alamat"])},
        "name_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama - Nomor Telepon"])},
        "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catatan"])},
        "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutup"])},
        "table": {
          "headers": {
            "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
            "serial_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No. Serial"])},
            "name_goods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama Barang"])},
            "qty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jumlah"])},
            "p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["P(Cm)"])},
            "l": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L(Cm)"])},
            "t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["T(Cm)"])},
            "cubic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kubikasi (m<sup>3</sup>)"])},
            "subtotal_cubic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subtotal Kubikasi"])},
            "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catatan"])}
          }
        }
      },
      "change_route": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ganti Alamat"])},
        "outlet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outlet"])},
        "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alamat"])},
        "new_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alamat Lengkap Baru"])},
        "placeholder_new_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan alamat lengkap baru"])},
        "reason_change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alasan Penggantian"])},
        "placeholder_reason_change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan Alasan Penggantian"])},
        "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutup"])},
        "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simpan"])}
      },
      "choose_address": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih Alamat"])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cari"])},
        "no_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada alamat, Silahkan tambahkan alamat pada"])}
      },
      "reasons": {
        "reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alasan"])},
        "placeholder_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan Alasan"])},
        "other_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alasan Lainnya"])},
        "placeholder_other_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan Alasan Lainnya"])},
        "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutup"])}
      }
    },
    "crop_image": {
      "image_position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posisikan Gambar"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Memuat"])},
      "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simpan"])}
    },
    "preview_file": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tinjau Lampiran"])},
      "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unduh"])},
      "download_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unduh Semua"])}
    }
  },
  "layout_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["l is language for every layout."])},
  "l": {
    
  },
  "pages_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This language for every pages."])},
  "dashboard": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dasbor"])},
    "filters": {
      "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Periode"])},
      "prev_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bulan Lalu"])},
      "this_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bulan Ini"])},
      "this_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minggu Ini"])},
      "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hari Ini"])},
      "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kastem"])}
    },
    "cards": {
      "new_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pesanan Baru"])},
      "onprogress_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pesanan Berlangsung"])},
      "complete_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pesanan Selesai"])},
      "unpaid_invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tagihan Belum Dibayar"])},
      "paid_invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tagihan Sudah Dibayar"])},
      "due_date_invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tagihan Jatuh Tempo"])}
    },
    "chart_donut": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persebaran Tujuan Pengiriman"])},
      "empty_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buat pesanan lebih banyak untuk mengetahui diagram persebaran pengiriman anda."])}
    },
    "chart_line": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jumlah Pengiriman"])},
      "empty_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buat Pesanan Untuk Melihat Diagram Perbandingan Pengiriman anda."])}
    }
  },
  "login": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selamat Datang"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masuk ke akun MOLOGIZ untuk memulai"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
    "email_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nama", "@", "email.com"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kata Sandi"])},
    "password_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kata Sandi"])},
    "remember": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingat Saya"])},
    "forgot_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lupa Kata Sandi ?"])},
    "login_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masuk Sekarang"])}
  },
  "register": {
    "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daftar"])},
    "account_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Isi data diri untuk mulai membuat akun MOLOGIZ"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
    "email_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nama", "@", "email.com"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kata Sandi"])},
    "password_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kata Sandi"])},
    "confirm_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfirmasi Kata Sandi"])},
    "confirm_password_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfirmasi Kata Sandi"])},
    "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daftar Sebagai"])},
    "role_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih Role"])},
    "personal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Personal"])},
    "personal_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mohon isi data personal. Nama akan ditampilkan pada profil anda."])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama"])},
    "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama Lengkap Anda"])},
    "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pertanyaan Keamanan"])},
    "question_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih Pertanyaan Keamanan"])},
    "answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jawaban"])},
    "answer_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jawaban dari Pertanyaan Keamanan"])},
    "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informasi Data Perusahaan"])},
    "company_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data perusahaan akan digunakan sebagai alamat penagihan"])},
    "company_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama Perusahaan"])},
    "company_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PT Perusahaan Anda"])},
    "pic_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama PIC"])},
    "pic_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama Lengkap Anda"])},
    "pic_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No. Telepon PIC"])},
    "pic_number_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No. Telepon PIC"])},
    "company_npwp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NPWP Perusahaan"])},
    "company_npwp_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nomor NPWP Perusahaan"])},
    "company_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Perusahaan"])},
    "company_email_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kontak", "@", "email.com"])},
    "company_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telepon Perusahaan"])},
    "company_phone_area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kode Area"])},
    "company_phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nomor Telepon"])},
    "company_npwp_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alamat Perusahaan"])},
    "company_npwp_address_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alamat perusahaan sesuai NPWP"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lanjutkan"])},
    "prev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kembali"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buat Akun"])}
  },
  "forgot_password": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lupa Kata Sandi"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kami akan mengirimkan kode keamanan untuk atur ulang kata sandimu."])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
    "email_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nama", "@", "email.com"])},
    "answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jawaban Anda"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Batal"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirim E-mail"])}
  },
  "reset_password": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atur Ulang Kata Sandi"])},
    "security_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kode Keamanan"])},
    "security_code_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kode Keamanan"])},
    "new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kata Sandi Baru"])},
    "new_password_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ketik Kata Sandi Baru Anda"])},
    "new_password_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfirmasi Kata Sandi Baru"])},
    "new_password_confirm_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ketik Ulang Kata Sandi Baru Anda"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Batal"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ubah Kata Sandi"])}
  },
  "reset_password_success": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permintaan Berhasil"])},
    "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Permintaan anda untuk mengatur ulang kata sandi berhasil. SIlahkan periksa secara berkala inbox <b>", _interpolate(_named("email")), "</b> karena kami akan mengirimkan tautan kode keamanan ke e-mail tersebut."])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saya Mengerti"])}
  },
  "order_create": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buat Pesanan"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih Layanan"])},
    "favourite": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pesanan Favorit"])},
      "option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opsi Tampilan"])}
    },
    "table": {
      "headers": {
        "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
        "delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengiriman"])},
        "typeTruck": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipe Truk"])},
        "load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muatan"])},
        "origin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asal"])},
        "destination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tujuan"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aksi"])}
      },
      "actions": {
        "reorder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pesan Ulang"])}
      }
    }
  },
  "order_history": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riwayat Pesanan"])},
    "favorite_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pesanan Favorit"])},
    "table": {
      "headers": {
        "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
        "shipmentNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No. Pengiriman"])},
        "etd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ETD"])},
        "shipmentType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipe"])},
        "originAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asal"])},
        "originOutlet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outlet Asal"])},
        "destinationAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tujuan"])},
        "destinationOutlet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outlet Tujuan"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aksi"])}
      },
      "actions": {
        "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lihat Detil"])}
      }
    }
  },
  "order_ftl": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buat Pesanan FTL"])},
    "search_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cari"])},
    "option_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opsi Tampilan"])},
    "table": {
      "headers": {
        "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
        "originOutlet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outlet Asal"])},
        "originAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alamat Asal"])},
        "destinationOutlet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outlet Tujuan"])},
        "destinationAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alamat Tujuan"])},
        "typeTruck": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipe - Sub Tipe Truk"])},
        "load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipe Muatan"])},
        "rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Harga"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aksi"])}
      },
      "actions": {
        "order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pesan"])}
      }
    }
  },
  "order_ftl_form": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah Pesanan FTL"])},
    "information": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informasi Pengiriman"])},
      "shipper_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama Shipper"])},
      "no_contract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nomor Kontrak"])},
      "truck_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipe Truk"])},
      "load_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipe Muatan"])},
      "backhaul_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Harga Backhaul"])},
      "regular_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Harga Reguler"])}
    },
    "nav": {
      "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selanjutnya"])},
      "prev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kembali"])},
      "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buat Pesanan"])}
    },
    "tab": {
      "tab1": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alamat Asal, Tujuan dan Barang Muatan"])},
        "origin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asal"])},
        "destination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tujuan"])},
        "outlet_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama Outlet"])},
        "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catatan"])},
        "fullname_sender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama Lengkap Pengirim"])},
        "fullname_recipient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama Lengkap Penerima"])},
        "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telepon Seluler"])},
        "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alamat Lengkap"])},
        "address_sender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alamat Lengkap Pengirim"])},
        "address_recipient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alamat Lengkap Penerima"])},
        "input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan"])}
      },
      "tab2": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waktu Pengiriman dan Informasi Tambahan"])},
        "date_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal & Waktu Pengiriman"])},
        "date_time_is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengiriman akan dilaksanakan pada:"])},
        "set_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tentukan Tanggal"])},
        "set_time_delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengiriman"])},
        "oclock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pukul"])},
        "additional_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informasi Tambahan"])},
        "amount_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jumlah Order"])},
        "no_ref": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No. Referensi"])},
        "no_ref_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nomor referensi bisa diisi dengan nomor AJU, container, barcode dan nomor dokumen pendukung lainnya"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lampiran Dokumen"])},
        "file_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Silahkan unggah SO, DO, PO atau dokumen pendukung pengiriman lainnya dengan maksimal ukuran 5MB"])},
        "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catatan"])},
        "input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan"])}
      },
      "tab3": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tinjauan Pesanan"])},
        "origin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asal"])},
        "destination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tujuan"])},
        "shipper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipper"])},
        "cubic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kubikasi"])},
        "total_goods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Barang"])}
      }
    },
    "table": {
      "headers": {
        "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
        "originOutlet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outlet Asal"])},
        "originAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alamat Asal"])},
        "destinationOutlet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outlet Tujuan"])},
        "destinationAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alamat Tujuan"])},
        "typeTruck": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipe - Sub Tipe Truk"])},
        "load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipe Muatan"])},
        "rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Harga"])}
      },
      "actions": {
        "order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pesan"])},
        "create_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buat Pesanan?"])},
        "order_without_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gagal unggah lampiran dokumen, pesanan tetap di terima tanpa lampiran"])}
      }
    }
  },
  "order_fcl": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buat Pesanan FCL"])}
  },
  "order_fcl_form": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah Pesanan FCL"])}
  },
  "order_ltl_form": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buat Pesanan LTL"])}
  },
  "order_detail": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detil Pengiriman"])},
    "cancel_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Batalkan Pesanan"])},
    "delivery_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riwayat Pengiriman"])},
    "info_detail": {
      "no_shipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No. Pengiriman"])},
      "etd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal Pengiriman"])},
      "truck_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipe Armada"])},
      "truck": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Armada (On Call)"])},
      "load_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipe Muatan"])},
      "no_ref": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nomor Referensi"])},
      "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lampiran Dokumen"])},
      "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catatan"])}
    },
    "rute": {
      "origin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alamat Asal"])},
      "destination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alamat Tujuan"])}
    }
  },
  "not_found": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Halaman Tidak Ditemukan!"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jangan khawatir, periksa kembali URL anda untuk memastikan tidak ada kesalahan penulisan tautan. <br /> Anda juga dapat kembali ke Home untuk melanjutkan ke web MOLOGIZ."])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kembali"])}
  },
  "master_address": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Master Alamat"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cari"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah"])},
    "table": {
      "actions": {
        "switch": {
          "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktif"])},
          "nonActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nonaktif"])}
        },
        "change_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ubah Status?"])},
        "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detil"])},
        "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hapus"])},
        "delete_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hapus Alamat?"])}
      },
      "headers": {
        "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
        "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alamat Lengkap"])},
        "outlet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outlet"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama"])},
        "phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telepon Seluler"])},
        "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catatan"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aksi"])}
      }
    },
    "form": {
      "detail_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detil Data"])},
      "add_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah Alamat"])},
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ubah"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama"])},
      "ph_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan nama"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telpon Seluler"])},
      "ph_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan telpon seluler"])},
      "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catatan"])},
      "ph_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan catatan"])},
      "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alamat Lengkap"])},
      "ph_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan Alamat Lengkap"])},
      "outlet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outlet"])},
      "ph_outlet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan outlet"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Batalkan"])},
      "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simpan"])},
      "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutup"])}
    }
  },
  "contract": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontrak FTL"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cari"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unduh"])},
    "table": {
      "headers": {
        "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
        "contract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontrak"])},
        "origin_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alamat Asal"])},
        "destination_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alamat Tujuan"])},
        "fleet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Armada"])},
        "load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipe Muatan"])},
        "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Harga"])},
        "addcost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biaya Tambahan"])},
        "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catatan"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aksi"])}
      },
      "actions": {
        "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detil"])}
      },
      "content": {
        "shipper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipper"])},
        "no_contract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No. Kontrak"])},
        "outlet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outlet"])},
        "province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provinsi"])},
        "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kota/Kabupaten"])},
        "district": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kecamatan"])},
        "overnight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inap"])},
        "multipickup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multipickup"])},
        "multidrop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multidrop"])}
      }
    },
    "no_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada data"])}
  },
  "contract_detail": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detil Kontrak"])},
    "no_contract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No. Kontrak"])},
    "shipper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipper"])},
    "delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipe Pengiriman"])},
    "fleet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipe Armada"])},
    "load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipe Muatan"])},
    "info_rute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informasi Rute"])},
    "table": {
      "headers": {
        "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipe"])},
        "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Harga"])},
        "price_return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Harga Retur"])},
        "price_backhaul": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Harga Backhaul"])},
        "overnight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inap"])},
        "multipickup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multipickup"])},
        "multidrop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multidrop"])},
        "start_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Priode Mulai"])},
        "end_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Priode Selesai"])},
        "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catatan"])}
      }
    },
    "province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provinsi"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kota/Kabupaten"])},
    "district": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kecamatan"])},
    "price_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daftar Harga"])}
  },
  "goods": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Master Barang"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cari"])},
    "delete_mark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hapus Tandai"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah"])},
    "switch": {
      "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktif"])},
      "nonActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nonaktif"])}
    },
    "form": {
      "detail_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detil Data"])},
      "add_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah Barang"])},
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ubah"])},
      "no_serial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Serial"])},
      "ph_no_serial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan no serial"])},
      "name_goods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama Barang"])},
      "ph_name_goods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan nama barang"])},
      "p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panjang"])},
      "ph_p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan Panjang"])},
      "l": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lebar"])},
      "ph_l": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan Lebar"])},
      "t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tinggi"])},
      "ph_t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan Tinggi"])},
      "cubic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kubikasi"])},
      "vol_metric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berat Volume Metrik"])},
      "max_load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MAX (GW VS VW)"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Batal"])},
      "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simpan"])},
      "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutup"])},
      "edit_goods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ubah Barang?"])},
      "add_goods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah Barang?"])}
    },
    "table": {
      "actions": {
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah Data"])},
        "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unggah Massal"])},
        "change_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ubah Status?"])},
        "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detil"])},
        "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hapus"])},
        "deleted": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hapus ", _interpolate(_named("msg")), "?"])},
        "delete_goods": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hapus Barang ", _interpolate(_named("msg")), "?"])},
        "delete_marks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hapus Barang yang di tandai?"])}
      },
      "headers": {
        "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
        "no_serial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Serial"])},
        "name_goods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama Barang"])},
        "p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panjang (Cm)"])},
        "l": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lebar (Cm)"])},
        "t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tinggi (Cm)"])},
        "cubic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kubikasi (m<sup>3</sup>)"])},
        "vol_metric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berat Volume Metrik"])},
        "max_load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MAX (GW VS VW)"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
        "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aksi"])}
      }
    }
  },
  "finance": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riwayat Tagihan"])},
    "warning_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status pembayaran tidak boleh kosong"])},
    "table": {
      "actions": {
        "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lihat Detil"])},
        "chip": {
          "paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dibayar"])},
          "partial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parsial"])},
          "unpaid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belum Bayar"])}
        }
      },
      "headers": {
        "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
        "invoice_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No. Tagihan"])},
        "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Penagihan"])},
        "invoice_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal Tagihan"])},
        "due_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tempo Pembayaran"])},
        "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
        "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aksi"])}
      }
    }
  },
  "finance_detail": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detil Tagihan"])},
    "invoice": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tagihan ", _interpolate(_named("msg"))])},
    "telp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telp"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "npwp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NPWP"])},
    "dear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kepada Yth."])},
    "invoice_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No. Tagihan"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal"])},
    "payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metode Pembayaran"])},
    "terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms"])},
    "due_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tempo Pembayaran"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "updated_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diperbarui Oleh"])},
    "transfer_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer Ke :"])},
    "no_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A.C (No. Rekening)"])},
    "name_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A.N (Atas Nama)"])},
    "total_delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Biaya Pengiriman"])},
    "ppn": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ppn ", _interpolate(_named("msg"))])},
    "total_invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Tagihan"])},
    "total_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Bayar"])},
    "invoice_left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sisa Tagihan"])},
    "sign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanda Tangan Pejabat Berwenang."])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kembali"])},
    "download_factur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unduh Faktur"])},
    "download_invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unduh Tagihan"])},
    "table": {
      "headers": {
        "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
        "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deskripsi"])},
        "shipment_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal Pengiriman"])},
        "rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Harga"])}
      }
    }
  },
  "notification": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifikasi"])},
    "alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifikasi akan di hapus otomatis jika lebih dari 7 hari"])},
    "filter_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal"])},
    "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arsipkan"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hapus"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cari"])},
    "mark_read": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tandai Dibaca"])},
    "change_item": {
      "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apakah notifikasi akan dihapus?"])},
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apakah notifikasi akan diarsipkan?"])},
      "read": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifikasi telah di baca?"])}
    },
    "change_items": {
      "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hapus notifikasi yang dipilih?"])},
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arsipkan notifikasi yang dipilih?"])},
      "read": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tandai telah dibaca yang dipilih?"])}
    },
    "open_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buka Tautan"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belum ada notifikasi saat ini.<br /> Segera buat pesanan dan dapatkan notifikasi pesanan terkait."])}
  },
  "profile": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil"])},
    "actions": {
      "confirm_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simpan Perubahan ?"])},
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ubah"])},
      "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simpan"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Batalkan"])}
    },
    "billing_address": {
      "main_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alamat Utama"])},
      "create_main_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buat Alamat Utama"])}
    },
    "tab": {
      "account": {
        "private_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Pribadi"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama Lengkap"])},
        "ph_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan alamat lengkap"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
        "ph_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan email"])},
        "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama Pengguna"])},
        "ph_username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan nama penngguna"])},
        "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hak Akses"])},
        "ph_role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan hak akses"])},
        "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No. Telepon"])},
        "ph_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan telpon seluler"])}
      },
      "billing": {
        "billing_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Penagihan"])},
        "account_bank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rekening Perusahaan"])},
        "bank_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama Bank"])},
        "no_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No. Rekening"])},
        "name_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama Akun Bank"])},
        "ph_name_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan nama akun bank"])},
        "document_completeness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kelengkapan Dokumen Penagihan"])},
        "cover_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cover Buku Rekening"])},
        "no_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada lampiran"])},
        "ready_upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siap Unggah"])},
        "edit_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ubah Lampiran"])},
        "upload_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unggah Lampiran"])},
        "billing_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alamat Penagihan"])},
        "no_billing_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak Ada Alamat Penagihan"])},
        "create_main_billing_address": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Jadikan ", _interpolate(_named("msg")), " sebagai alamat utama penagihan?"])},
        "success_billing_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berhasil Dijadikan Alamat Utama"])}
      },
      "company": {
        "company_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Perusahaan"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama Perusahaan"])},
        "ph_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan nama perusahaan"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Perusahaan"])},
        "ph_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan email perusahaan"])},
        "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telepon Perusahaan"])},
        "ph_phone_area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kode"])},
        "ph_phone_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan no telepon"])},
        "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alamat Perusahaan"])},
        "ph_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan alamat perusahaan"])},
        "company_completeness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kelengkapan Perusahaan"])},
        "npwp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NPWP Perusahaan"])},
        "ph_npwp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan NPWP Perusahaan"])},
        "nib": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NIB Perusahaan"])},
        "ph_nib": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan NIB"])},
        "document_completeness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kelengkapan Dokumen"])},
        "logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo Perusahaan"])},
        "ready_upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siap Unggah"])},
        "edit_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ubah Lampiran"])},
        "upload_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unggah Lampiran"])},
        "no_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belum ada lampiran"])}
      },
      "notification": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifikasi"])},
        "order_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status Pesanan"])},
        "payment_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status Pembayaran"])},
        "new_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pesanan Baru"])},
        "received_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pesanan Diterima"])},
        "to_destination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menuju Lokasi Asal"])},
        "load_goods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Memuat Barang"])},
        "complete_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pesanan Selesai"])},
        "create_new_invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pembuatan Tagihan Baru"])},
        "over_due": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tagihan Jatuh Tempo"])},
        "invoice_paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tagihan Dibayar"])}
      },
      "security": {
        "security_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keamanan Akun"])},
        "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pertanyaan Keamanan"])},
        "ph_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan pertanyaan keamanan"])},
        "answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jawaban"])},
        "ph_answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan Jawaban"])},
        "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kata Sandi"])},
        "current_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kata Sandi Saat Ini"])},
        "new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kata Sandi Baru"])},
        "confirm_new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfirmasi Kata Sandi Baru"])},
        "change_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ubah Kata Sandi?"])}
      }
    },
    "menu": {
      "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Personal"])},
      "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Perusahaan"])},
      "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Penagihan"])},
      "security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keamanan Akun"])},
      "notification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifikasi"])},
      "avatar_changed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avatar berhasil diubah"])}
    },
    "table": {
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
      "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pesan"])},
      "notification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifikasi"])}
    }
  },
  "revision_history": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riwayat Revisi"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cari"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unduh"])},
    "no_data_download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada data untuk di unduh"])},
    "success_download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berhasil unduh data"])},
    "table": {
      "headers": {
        "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
        "invoice_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No. Tagihan"])},
        "billing_company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perusahaan Penagih"])},
        "invoice_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal Tagihan"])},
        "invoice_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategori Tagihan"])},
        "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Tagihan"])},
        "factur_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No. Faktur"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])}
      },
      "headers_expand": {
        "invoice_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No. Tagihan"])},
        "invoice_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal Tagihan"])},
        "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal Diperbarui"])},
        "updated_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diperbarui Oleh"])},
        "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catatan"])}
      }
    }
  },
  "live_tracking": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pelacakan Berlangsung"])},
    "updated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Diperbarui ", _interpolate(_named("msg"))])},
    "shipment_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No. Pengiriman"])},
    "shipment_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tgl. Pengiriman"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipe"])},
    "truck_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipe Truk"])},
    "sub_truck_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sub Tipe Truk"])},
    "origin_route": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rute Asal"])},
    "destination_route": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rute Tujuan"])},
    "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salin"])},
    "police_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No. Pplisi"])},
    "driver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengemudi"])},
    "driver_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No. Tlp Pengemudi"])},
    "success_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengirim berhasil disalin"])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Memuat"])},
    "nothing_shipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada pengiriman"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cari"])},
    "list_shipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daftar Pengiriman"])},
    "filter": {
      "active_shipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengiriman Aktif"])},
      "latest_shipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengiriman Terbaru"])},
      "longest_shipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengiriman Terlama"])},
      "latest_etd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ETD Terbaru"])},
      "longest_etd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ETD Terlama"])}
    }
  },
  "list_staff": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daftar Staff"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cari"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah"])},
    "table": {
      "actions": {
        "switch": {
          "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktif"])},
          "nonActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nonaktif"])}
        },
        "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detil"])},
        "change_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ubah Status?"])}
      },
      "headers": {
        "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
        "full_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama Lengkap"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
        "access_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level Akses"])},
        "phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No. Telepon Seluler"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aksi"])}
      }
    },
    "form": {
      "detail_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detil Data"])},
      "add_staff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah Staff"])},
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ubah"])},
      "full_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama Lengkap"])},
      "ph_full_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan nama lengkap"])},
      "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama Pengguna"])},
      "ph_username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan nama penngguna"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
      "ph_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan email"])},
      "phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No. Telepon Seluler"])},
      "ph_phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan no. telpon seluler"])},
      "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hak Akses"])},
      "ph_role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan hak akses"])},
      "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alamat Lengkap"])},
      "ph_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan Alamat Lengkap"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kata Sandi"])},
      "ph_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan kata sandi"])},
      "confirm_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfirmasi Kata Sandi"])},
      "ph_confirm_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan Konfirmasi Kata Sandi"])},
      "security_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pertanyaan Keamanan"])},
      "ph_security_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan Pertanyaan Keamanan"])},
      "answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jawaban"])},
      "ph_answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan Jawaban"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Batalkan"])},
      "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simpan"])},
      "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutup"])},
      "added_staff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah Staff?"])},
      "change_staff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ubah Staff?"])}
    }
  },
  "routes_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This language for every routes."])},
  "r_auth": {
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masuk"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daftar"])},
    "forgot_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lupa Password"])},
    "forgot_password_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permintaan Lupa Kata Sandi Berhasil"])},
    "reset_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atur Ulang Kata Sandi"])}
  },
  "r_order": {
    "transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaksi"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buat Pesanan"])},
    "create_ftl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buat Pesanan FTL"])},
    "create_ftl_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah Pesanan FTL"])},
    "create_fcl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buat Pesanan FCL"])},
    "create_fcl_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah Pesanan FCL"])},
    "create_ltl_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buat Pesanan LTL"])},
    "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riwayat Pesanan"])},
    "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detil Pengiriman"])}
  },
  "r_dashboard": {
    "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dasbor"])}
  },
  "r_corporate": {
    "corporate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korporasi"])},
    "master_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Master Alamat"])},
    "contract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontrak"])},
    "contract_FTL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontrak FTL"])},
    "contract_detail_FTL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detil Kontrak FTL"])},
    "goods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daftar Barang"])}
  },
  "r_finance": {
    "finance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keuangan"])},
    "finance_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riwayat Tagihan"])},
    "finance_detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Tagihan"])}
  },
  "r_notfound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Halaman Tidak Ditemukan"])},
  "r_notification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifikasi"])},
  "r_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil"])},
  "r_report": {
    "report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laporan"])},
    "ar_invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tagihan AR"])},
    "revision_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riwayat Revisi"])}
  },
  "r_live_tracking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pelacakan Langsung"])},
  "r_users": {
    "list_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daftar Pengguna"])},
    "list_staff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daftar Staff"])}
  },
  "shipment_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is shipment status."])},
  "shipment": {
    "status": {
      "01": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pesanan Baru"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pesanan sedang diproses, menunggu balasan dari Transporter"])},
        "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["green"])}
      },
      "02": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pesanan Dibalas"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pesanan telah dibalas oleh Transporter"])},
        "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["yellow"])}
      },
      "03": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pesanan Diterima"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pesanan telah diterima oleh Transporter"])},
        "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["green"])}
      },
      "04": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Penugasan"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transporter telah menugaskan pengemudi"])},
        "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["yellow"])}
      },
      "05": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menuju Lokasi Asal"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengemudi sedang menuju lokasi penjemputan"])},
        "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["yellow"])}
      },
      "06": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menunggu Muat Barang"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengemudi sedang menunggu barang dimuat"])},
        "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["yellow"])}
      },
      "07": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Memuat Barang"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Barang sedang dimuat"])},
        "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["yellow"])}
      },
      "08": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menuju Lokasi Tujuan"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengemudi sedang menuju lokasi tujuan"])},
        "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["yellow"])}
      },
      "09": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menunggu Bongkar Barang"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengemudi sedang menunggu barang dibongkar"])},
        "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["yellow"])}
      },
      "10": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membongkar Barang"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Barang sedang dibongkar"])},
        "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["yellow"])}
      },
      "11": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terkirim"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengiriman barang telah diterima PIC tujuan dan masih tunggu konfirmasi penerimaan"])},
        "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["green"])}
      },
      "12": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pesanan Selesai"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengiriman barang telah dikonfirmasi oleh Shipper"])},
        "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["green"])}
      },
      "13": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dibatalkan"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pesanan dibatalkan"])},
        "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gray"])}
      },
      "14": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dikomplain"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pesanan dikomplain"])},
        "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gray"])}
      },
      "15": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komplain Menunggu Persetujuan"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiket pengaduan telah selesai. Tiket wajib dikonfirmasi"])},
        "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["yellow"])}
      },
      "16": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pesanan Baru, Incomplete"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pesanan Baru Untuk Shipper Korporate. Tower Controll Harus Lengkapi Alamat Detil Terlebih Dahulu Seblum Transporter Melakukan Update Pengiriman."])},
        "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["green"])}
      },
      "17": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menunggu Persetujuan"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transporter telah menambah biaya tambahan, menunggu persetujuan tower control"])},
        "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["yellow"])}
      },
      "18": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menunggu Verifikasi"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pesanan dengan penugasan khusus, perlu konfirmasi dari atasan."])},
        "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["yellow"])}
      },
      "19": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diselesaikan Driver"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pesanan ini telah diselesaikan driver, menunggu penambahan biaya lain-lain"])},
        "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["green"])}
      },
      "20": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biaya Ditolak"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biaya tambahan ditolak CT, silahkan perbaiki data"])},
        "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["yellow"])}
      },
      "21": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gagal Kirim"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengiriman gagal kirim dikarenakan suatu hal"])},
        "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["grey"])}
      }
    }
  },
  "category_notif_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is category of notif"])},
  "notif": {
    "category": {
      "01": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice"])},
        "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IconInvoice"])},
        "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["green"])}
      },
      "02": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matching Load"])},
        "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IconTruckFast"])},
        "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["primary"])}
      },
      "03": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Truck Post Offer Unaccepted"])},
        "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IconTruckFast"])},
        "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["primary"])}
      },
      "04": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transporter Order"])},
        "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IconTruckFast"])},
        "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["primary"])}
      },
      "05": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipper Order"])},
        "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IconTruckFast"])},
        "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["primary"])}
      },
      "06": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ditinjau ulang"])},
        "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IconTruckFast"])},
        "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["primary"])}
      },
      "07": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complaint"])},
        "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IconTruckFast"])},
        "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["primary"])}
      },
      "08": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cargo Post Offer"])},
        "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IconTruckFast"])},
        "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["primary"])}
      },
      "09": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Truck Post Offer"])},
        "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IconTruckFast"])},
        "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["primary"])}
      },
      "10": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Truck Post Offer Response"])},
        "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IconTruckFast"])},
        "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["primary"])}
      }
    }
  }
}