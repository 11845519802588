<template>
  <label
    for="operational_time"
    :class="{ 'text-error': props.error.length }"
    class="block mb-1 ml-4 text-ellipsis overflow-hidden text-sm font-medium leading-6 text-gray-900"
  >
    {{ $t("el.field_time.title") }}
  </label>
  <div
    :class="{
      'outline outline-1 outline-offset-0 outline-error': props.error.length,
    }"
    class="w-full bg-gray-4 rounded-full px-3 flex items-center justify-between gap-0.5 times-picker"
  >
    <field-text
      class="!w-fit"
      :class="{ 'first-times-picker': !myTime.time1 }"
      height="h-[34px]"
      name="openTime"
      :idInput="`time-${idInput}`"
      width="w-[80px]"
      classes="!px-0"
      :flat="true"
      @input="changeTime"
      clearable
      :disabled="props.disabled"
      type="time"
      v-model="myTime.time1"
    />
    <base-icon-mdi :path="mdiArrowRight" color="gray" />
    <field-text
      class="!w-fit"
      height="h-[34px]"
      name="closeTime"
      width="w-[80px]"
      type="time"
      :flat="true"
      @input="changeTime"
      :disabled="props.disabled"
      clearable
      :class="{ 'second-times-picker': !myTime.time2 }"
      v-model="myTime.time2"
    />
    <base-icon-mdi
      class="max-sm:hidden cursor-pointer"
      :path="mdiClockOutline"
      @click="openTime()"
      color="gray"
    />
  </div>
  <div
    v-if="props.error.length"
    class="px-4 whitespace-normal text-[10px] leading-[11px] pt-1 text-error"
  >
    {{ error[0].$message }}
  </div>
</template>
<script setup>
import { mdiArrowRight, mdiClockOutline } from "@mdi/js";
import { useComponentStore } from "@/stores/component";
import { onMounted, ref, watch } from "vue";
import FieldText from "./FieldText.vue";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
const componentStore = useComponentStore();
componentStore.setUid();
const idInput = ref(componentStore.uid);
const props = defineProps({
  modelValue: {
    type: Object,
    default: function () {
      return {
        time1: "",
        time2: "",
      };
    },
  },
  error: {
    type: Array,
    default: function () {
      return [];
    },
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(["update:modelValue"]);
const myTime = ref({
  time1: "",
  time2: "",
});
const changeTime = () => {
  emit("update:modelValue", myTime.value);
};
const openTime = () => {
  if (props.disabled == false) {
    let idTime = `time-${idInput.value}`;
    document.getElementById(idTime).showPicker();
  }
};
const setLabelTimes = () => {
  document.documentElement.style.setProperty(
    "--root-text-open-time",
    `"${t("el.field_time.open_time")}"`
  );
  document.documentElement.style.setProperty(
    "--root-text-close-time",
    `"${t("el.field_time.close_time")}"`
  );
};
watch(
  () => props.modelValue,
  (newVal) => {
    if (
      newVal.time1 !== myTime.value.time1 ||
      newVal.time2 !== myTime.value.time2
    ) {
      myTime.value = newVal;
    }
    setLabelTimes();
  }
);
onMounted(() => {
  if (props.modelValue.time1) {
    myTime.value = props.modelValue;
  }
});
</script>
<style>
:root {
  --root-text-open-time: "Jam Buka";
  --root-text-close-time: "Jam Tutup";
}
.times-picker input[type="time"]::-webkit-inner-spin-button,
.times-picker input[type="time"]::-webkit-outer-spin-button,
.times-picker input[type="time"]::-webkit-clear-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: none;
}
.times-picker input[type="time"]::-webkit-calendar-picker-indicator {
  /* background: none;
  display: none; */
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}

.times-picker .first-times-picker input[type="time"]:before {
  content: var(--root-text-open-time);
  color: #787878;
  position: absolute;
  background: #f3f3f3;
}

.times-picker .first-times-picker input[type="time"]:focus:before {
  width: 0;
  content: "";
}

.times-picker .second-times-picker input[type="time"]:before {
  content: var(--root-text-close-time);
  color: #787878;
  position: absolute;
  background: #f3f3f3;
}

.times-picker .second-times-picker input[type="time"]:focus:before {
  width: 0;
  content: "";
}
</style>
