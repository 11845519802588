<script>
// IMPORT CAROUSEL FOR AUTH
import AuthCarousel from "@/components/modules/carousel/AuthCarousel.vue";
import { useDataOnBoardingStore } from "@/stores/master-data/onboarding";
import { computed, getCurrentInstance, onMounted } from "vue";
import { useRoute } from "vue-router";
export default {
  components: { AuthCarousel },
  setup() {
    // SLIDER CONTENT
    const { proxy } = getCurrentInstance();
    const route = useRoute();
    const storeOnBoarding = useDataOnBoardingStore();
    // const slider
    const slider = computed(() => {
      return storeOnBoarding.items;
    });
    const sliderPage = computed(() => {
      switch (route.name) {
        case "auth-login":
          return storeOnBoarding.getItems("LOGIN").map((x) => {
            return {
              image: x.attachment,
              title: x.title,
              description: x.description,
            };
          });
        case "auth-register":
          return storeOnBoarding.getItems("REGISTER").map((x) => {
            return {
              image: x.attachment,
              title: x.title,
              description: x.description,
            };
          });
        case "auth-forgot-password":
          return storeOnBoarding.getItems("FORGOTPASSWORD").map((x) => {
            return {
              image: x.attachment,
              title: x.title,
              description: x.description,
            };
          });
        case "auth-forgot-password-success":
          return storeOnBoarding.getItems("FORGOTPASSWORD").map((x) => {
            return {
              image: x.attachment,
              title: x.title,
              description: x.description,
            };
          });
        case "auth-reset-password":
          return storeOnBoarding.getItems("RESETPASSWORD").map((x) => {
            return {
              image: x.attachment,
              title: x.title,
              description: x.description,
            };
          });
        case "auth-otp-verification":
          return storeOnBoarding.getItems("LOGIN").map((x) => {
            return {
              image: x.attachment,
              title: x.title,
              description: x.description,
            };
          });
        default:
          return [];
      }
    });
    const getData = async () => {
      if (slider.value.length == 0) {
        proxy.$component.setLoading(true);
        try {
          let payload = {
            platform: "WEB",
            service: "GENERAL",
          };
          await storeOnBoarding.get(payload);
          proxy.$component.setLoading(false);
        } catch (error) {
          proxy.$component.setLoading(false);
        }
      }
    };
    onMounted(() => {
      getData();
    });
    return { sliderPage };
  },
};
</script>

<template>
  <section class="w-full bg-white h-screen" v-if="sliderPage.length > 0">
    <div class="relative overflow-hidden">
      <div class="w-full flex">
        <div class="w-full h-screen">
          <auth-carousel :slider="sliderPage" />
        </div>
      </div>
    </div>
  </section>
</template>
