import { createApp } from "vue";
// CALL PINIA
import { createPinia } from "pinia";

import App from "./App.vue";
import "./registerServiceWorker";
// import "./firebase";
// toast
import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";
// end toast
import "./assets/styles/app.css";
import "./assets/styles/css/main.css";
// style of v calendar
import "v-calendar/style.css";
import filters from "./utils/filters";
// permissions route
import router from "@/permissions";
import GlobalElements from "./components/elements/register";
import IconSvg from "./components/icons/IconSvg.vue";
import { useComponentStore } from "./stores/component";
import i18n from "./i18n";
import VueGoogleMaps from "@fawmi/vue-google-maps";
// add v-calendar
import { setupCalendar } from "v-calendar";
import { useUserStore } from "./stores/user";
// INIT PINIA
const pinia = createPinia();
const app = createApp(App);
app.use(pinia);
app.use(GlobalElements);
app.use(i18n);
app.use(router);
app.use(Toast, {
  transition: "Vue-Toastification__bounce",
  maxToasts: 20,
  closeOnClick: false,
  draggable: false,
  newestOnTop: true,
});
app.component("IconSvg", IconSvg);
// add vue google maps
app.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_KEY,
    libraries: "places,geometry",
    region: "ID",
    language: "id",
    loading: "async",
    v: "3.57",
  },
});
// Use calendar defaults (optional)
app.use(setupCalendar, {});
// filter global
app.config.globalProperties.$filters = filters;
// component store global
app.config.globalProperties.$component = useComponentStore();
// component user global
app.config.globalProperties.$user = useUserStore();
app.mount("#app");
