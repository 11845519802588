var CryptoJS = require("crypto-js");
const secretKey = process.env.VUE_APP_CRYPTOJS_KEY;
// ENCRYPT DECRYPT AES
export function encrypt(text) {
  if (
    text !== null &&
    text !== "null" &&
    text !== "undefined" &&
    text !== undefined &&
    text !== ""
  ) {
    var ciphertext = CryptoJS.AES.encrypt(
      JSON.stringify(text),
      secretKey
    ).toString();
    return ciphertext;
  } else {
    return "";
  }
}

export function decrypt(text) {
  if (
    text !== null &&
    text !== "null" &&
    text !== "undefined" &&
    text !== undefined &&
    text !== ""
  ) {
    var bytes = CryptoJS.AES.decrypt(text, secretKey);
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decryptedData;
  } else {
    return "";
  }
}

// ENCRYPT DECRYPT SHA256
var iv = CryptoJS.enc.Base64.parse(""); //giving empty initialization vector
var key = CryptoJS.SHA256(secretKey); //hashing the key using SHA256

export function encryptData(text) {
  if (
    text !== null &&
    text !== "null" &&
    text !== "undefined" &&
    text !== undefined &&
    text !== ""
  ) {
    var encryptedString = CryptoJS.AES.encrypt(JSON.stringify(text), key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    return encryptedString.toString();
  } else {
    return "";
  }
}

export function decryptData(encrypted) {
  if (
    encrypted !== null &&
    encrypted !== "null" &&
    encrypted !== "undefined" &&
    encrypted !== undefined &&
    encrypted !== ""
  ) {
    var decrypted = CryptoJS.AES.decrypt(encrypted, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    return decrypted.toString(CryptoJS.enc.Utf8);
  } else {
    return "";
  }
}
