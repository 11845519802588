import { DashboardLayout } from "@/layouts";
const dashboard = [
  {
    path: "/profile",
    component: DashboardLayout,
    meta: {
      title: "r_profile",
      icon: "IconUsers",
      group: "apps",
    },
    single: false,
    hidden: true,
    children: [
      {
        path: "",
        name: "profile",
        meta: {
          title: "r_profile",
          roles: ["userprofile:read", "Shipper Guest"],
        },
        hidden: true,
        component: () => import("@/views/shipper/profile/ProfilePage.vue"),
      },
      {
        path: "webhook-histories",
        name: "webhook_histories",
        meta: {
          title: "r_webhook_history",
          roles: ["webhook:read"],
        },
        hidden: true,
        component: () =>
          import("@/views/shipper/profile/ProfileWebHookHistory.vue"),
      },
      // {
      //   path: "address",
      //   name: "profile_address",
      //   meta: {
      //     title: "r_address",
      //     roles: ["fleet:read"],
      //   },
      //   hidden: true,
      //   component: () => import("@/views/shipper/corporate/pool/PoolPage.vue"),
      // },
    ],
  },
];

export default dashboard;
