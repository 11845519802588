<template>
  <button @click="clickCopy()" class="m-0 p-0 bg-none leading-none">
    <icon-svg
      :color="props.color"
      icon="IconCopy"
      width="12"
      height="14"
    ></icon-svg>
  </button>
</template>

<script setup>
import { useToast } from "vue-toastification";

const props = defineProps({
  value: {
    type: [String, Number],
    default: "",
  },
  color: {
    type: [String, Number],
    default: "black",
  },
  customMessage: {
    type: String,
    default: "",
  },
});
const toast = useToast();
const copiedText = (val, msg = "") => {
  navigator.clipboard.writeText(val);
  if (val) {
    if (msg) {
      toast.success(msg);
    } else {
      toast.success("" + val + " berhasil disalin");
    }
  } else {
    toast.warning("Gagal salin teks");
  }
};
const clickCopy = () => {
  copiedText(props.value, props.customMessage);
};
defineExpose({
  copiedText,
});
</script>
