import { defineStore } from "pinia";
// import axios from "axios";
import i18n from "@/i18n";
import route from "@/router";
import getPageTitle from "@/utils/get-page-title";
const dayjs = require("dayjs");
export const useMainStore = defineStore("main", {
  state: () => ({
    // SIDEBAR
    showSidebar: true,
    currentCurrency: "id",
    // LANGUAGE
    currentLang: "id",
    listLang: [
      {
        id: "id",
        name: "Indonesian",
        icon: "icon-id",
      },
      {
        id: "en",
        name: "English",
        icon: "icon-en",
      },
      // {
      //   id: "ar",
      //   name: "عربي",
      //   icon: "icon-ar",
      // },
      // {
      //   id: "cn",
      //   name: "北方",
      //   icon: "icon-cn",
      // },
    ],
    // SCREEN SIZE
    screenSize: "lg",
    // PaGINATION SETTING
    paginationPerPage: 12,
    // socket
    socketActive: false,
  }),
  getters: {
    // get current language
    getCurrentLang(state) {
      return state.listLang.find((x) => x.id == state.currentLang);
    },
  },
  actions: {
    setSidebar(val) {
      this.showSidebar = val;
    },
    setScreenSize(val) {
      if (val >= 1400) {
        this.screenSize = "2xl";
      } else if (val >= 1280) {
        this.screenSize = "xl";
      } else if (val >= 1024) {
        this.screenSize = "lg";
      } else if (val >= 768) {
        this.screenSize = "md";
      } else if (val > 0) {
        this.screenSize = "sm";
      }
    },
    setLang(id) {
      id = id.toLowerCase();
      this.currentLang = id;
      i18n.global.locale.value = id;
      document.title = getPageTitle(route.currentRoute.value.meta.title);
      document.documentElement.setAttribute("lang", id);
      dayjs.locale(id);
    },
    setSocket(val) {
      this.socketActive = val;
    },
    // setUser(payload) {
    //   if (payload.name) {
    //     this.userName = payload.name;
    //   }
    //   if (payload.email) {
    //     this.userEmail = payload.email;
    //   }
    //   if (payload.avatar) {
    //     this.userAvatar = payload.avatar;
    //   }
    // },

    // fetch(sampleDataKey) {
    //   axios
    //     .get(`data-sources/${sampleDataKey}.json`)
    //     .then((r) => {
    //       if (r.data && r.data.data) {
    //         this[sampleDataKey] = r.data.data;
    //       }
    //     })
    //     .catch((error) => {
    //       alert(error.message);
    //     });
    // },
  },
});
