import { defineStore } from "pinia";
import { get } from "@/api/master-data/files";

export const useFilesStore = defineStore("files", {
  state: () => ({}),
  getters: {},
  actions: {
    get(params) {
      return new Promise((resolve, reject) => {
        get(params)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
});
