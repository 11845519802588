<script setup>
import { computed } from "vue";
import { useRoute } from "vue-router";

const route = useRoute();
const linkNow = computed(() => {
  return route.name;
});
</script>

<template>
  <div class="flex flex-wrap justify-end items-center gap-4">
    <select-language />
    <div class="flex items-center gap-x-4">
      <base-button
        @click.stop="$router.push({ name: 'auth-login' })"
        color="primary"
        :outlined="linkNow !== 'auth-login'"
        >{{ $t("m.header_auth.login") }}</base-button
      >
      <base-button
        @click.stop="$router.push({ name: 'auth-register' })"
        :outlined="linkNow !== 'auth-register'"
        color="primary"
        >{{ $t("m.header_auth.register") }}</base-button
      >
    </div>
  </div>
</template>
