<template>
  <div class="flex justify-between items-center">
    <span>New content is available!</span>
    <button @click.stop="clicked">Reload</button>
  </div>
</template>
<script>
export default {
  methods: {
    clicked() {
      this.$emit("click");
    },
  },
};
</script>
