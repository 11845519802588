export const apiGateway = process.env.VUE_APP_BASE_API_GATEWAY;
export const apiBase = process.env.VUE_APP_BASE_API;
export const apiMasterData = process.env.VUE_APP_MASTERDATA_API;
export const apiFleet = process.env.VUE_APP_BASE_API_FLEET;
export const apiTransaction = process.env.VUE_APP_BASE_TRANSACTION;
export const apiTracking = process.env.VUE_APP_TRACKING_API;
export const apiMessaging = process.env.VUE_APP_MESSAGING_API;

// new endpoint
export const apiDelivery = process.env.VUE_APP_API;

// const
export const captchaKey = process.env.VUE_APP_CAPTCHA_KEY;

export const oldShipperUrl = process.env.VUE_APP_OLD_SHIPPER_URL;
