<template>
  <base-modal v-model="modal" persistent max-width="500px">
    <div
      :key="keys"
      class="py-6 px-[30px] bg-white shadow-lg rounded-[36px] relative max-md:py-4 max-md:px-5 max-md:rounded-2xl"
    >
      <h4 class="text-base font-bold text-black mb-2 max-md:mb-1">
        {{ $t("m.general.modal_select_branch.title") }}
      </h4>
      <p class="text-xs font-medium mb-4 max-md:mb-3">
        {{ $t("m.general.modal_select_branch.desc") }}
      </p>
      <div class="flex gap-2 w-full max-full mb-6 max-md:mb-4">
        <field-text
          appendIconLeft="IconSearch"
          height="h-8"
          rounded="rounded-lg"
          clearable
          colorIcon="disabled"
          v-model="search"
          :placeholder="$t('m.general.modal_select_branch.search')"
        />
      </div>
      <div
        v-if="items.length > 0"
        class="mb-5 grid gap-5 scrollY max-h-[340px] px-1 overflow-hidden overflow-y-auto max-md:mb-3 max-md:gap-4"
      >
        <div
          v-for="item in items"
          :key="item.id"
          :class="
            item.id == selectBranch
              ? 'border-primary shadow-md'
              : 'border-gray-4'
          "
          class="flex px-5 py-5 border rounded-xl max-md:p-2.5 transition-all ease-in-out"
        >
          <div class="w-[30px]">
            <base-radio v-model="selectBranch" :value="item.id"> </base-radio>
          </div>
          <div style="width: calc(100% - 30px)">
            <div class="text-base font-bold max-md:text-sm mb-1">
              {{ item.name }}
            </div>
            <div class="text-xs font-medium mb-1">
              {{ item.address }}
            </div>
            <div class="text-[13px] leading-5 font-bold">
              PIC :
              {{ item.picName }} - {{ item.picNo }}
            </div>
          </div>
        </div>
      </div>
      <div
        v-else
        class="mb-5 w-full flex justify-center items-center text-sm font-medium h-[80px] border border-gray-4 rounded-xl max-md:mb-3"
      >
        {{ $t("m.general.modal_select_branch.no_data") }}
      </div>
      <div class="flex justify-end gap-3">
        <base-button
          @click="close()"
          outlined
          width="min-w-[70px]"
          height="h-[34px]"
        >
          {{ $t("m.general.modal_select_branch.close") }}
        </base-button>
        <base-button
          @click="submitData()"
          width="min-w-[70px]"
          height="h-[34px]"
          :disabled="items.length == 0 || !selectBranch"
        >
          {{ $t("m.general.modal_select_branch.select") }}
        </base-button>
      </div>
    </div>
  </base-modal>
</template>
<script setup>
import { ref, watch, computed, getCurrentInstance } from "vue";
import { useUserStore } from "@/stores/user";
import { useToast } from "vue-toastification";
import { useBranchStore } from "@/stores/master-data/branch";

// store
const storeUser = useUserStore();
const storeBranch = useBranchStore();

// utils
const { proxy } = getCurrentInstance();
const toast = useToast();

// variable
const modal = ref(false);
const selectBranch = ref("");
const search = ref("");
const keys = ref(1);

// computed
const isShow = computed(() => {
  return storeUser.showSelectBranch;
});
const current = computed(() => {
  return storeUser.currentBranch;
});
const items = computed(() => {
  let list = storeBranch.items;
  if (search.value) {
    list = list.filter((item) => {
      var truth = [];
      for (let i = 0; i < Object.keys(item).length; i++) {
        let key = Object.keys(item)[i];
        let dd = item[key];
        if (typeof dd == "string")
          truth.push(dd.toLowerCase().includes(search.value.toLowerCase()));
        if (typeof dd == "number")
          truth.push(String(dd).includes(search.value));
      }
      return truth.some((x) => x);
    });
  }
  return list;
});

// watch
watch(modal, (val) => {
  modal.value = val;
  if (val) {
    parseForm();
    getData();
  }
});
watch(isShow, (val) => {
  modal.value = val;
});

// function
const close = () => {
  selectBranch.value = "";
  keys.value++;
  storeUser.setShowSelectBranch(false);
};
const parseForm = async () => {
  if (Object.keys(current.value).length > 0) {
    selectBranch.value = current.value.id;
  } else {
    selectBranch.value = "";
  }
};
const submitData = async () => {
  if (current.value.id !== selectBranch.value) {
    proxy.$component.setLoading(true);
    try {
      let payload = {
        id: selectBranch.value,
      };
      let res = await storeUser.changeBranch(payload);
      let newValue = items.value.find((x) => x.id == selectBranch.value);
      storeUser.setCurrentBranch(newValue);
      toast.success(res.message);
      setTimeout(async () => {
        await storeUser.checkFeatureLTL();
        proxy.$component.setLoading(false);
        modal.value = false;
        location.reload();
      }, 1000);
    } catch (error) {
      proxy.$component.setLoading(false);
    }
  } else {
    close();
  }
};
const getData = async () => {
  proxy.$component.setLoading(true);
  try {
    await storeBranch.get();
    proxy.$component.setLoading(false);
  } catch (error) {
    proxy.$component.setLoading(false);
  }
};
</script>
