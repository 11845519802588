<template>
  <base-modal v-model="modal" persistent max-width="550px">
    <div class="p-8 bg-white shadow-lg rounded-[25px] relative max-md:p-5">
      <!-- information -->
      <div v-if="tab == 1">
        <h3 class="text-xl font-bold mb-5">
          {{ $t("m.email_verification.modal.title") }}
        </h3>
        <p class="text-xs mb-6 font-medium">
          {{ $t("m.email_verification.modal.desc") }}
        </p>
        <div class="grid gap-6 mb-3">
          <div class="flex gap-4" v-for="(x, i) in information" :key="i">
            <div>
              <icon-svg :icon="x.icon" color="primary"></icon-svg>
            </div>
            <div>
              <h4 class="text-[13px] leading-[18px] font-bold mb-2">
                {{ $t(x.title) }}
              </h4>
              <p class="mb-0 text-xs leading-4 font-medium">
                {{ $t(x.desc) }}
              </p>
            </div>
          </div>
        </div>
        <div class="flex justify-between gap-4">
          <button
            class="text-primary text-xs underline bg-none p-0 m-0 font-semibold"
            @click="logout"
          >
            {{ $t("m.email_verification.modal.sign_out") }}
          </button>
          <div class="flex items-center">
            <base-button @click="tab = 2">
              {{ $t("m.email_verification.modal.next") }}
            </base-button>
          </div>
        </div>
      </div>
      <!-- form email -->
      <div v-else-if="tab == 2">
        <h3 class="text-xl font-bold mb-5">
          {{ $t("m.email_verification.modal.email_title") }}
        </h3>
        <p class="text-xs mb-8 font-medium">
          {{ $t("m.email_verification.modal.email_desc") }}
        </p>
        <p class="text-xs mb-8 font-bold">
          {{ $t("m.email_verification.modal.email_note") }}
        </p>
        <div
          class="mb-[102px] font-medium text-xs"
          v-if="isChangeEmail == false"
        >
          <p class="mb-0 font-bold">
            {{ $t("m.email_verification.modal.email_your") }}
          </p>
          <p class="mb-7">{{ formEmail.email }}</p>
          <a
            href="#change-email"
            @click="isChangeEmail = true"
            class="text-primary underline font-bold"
          >
            {{ $t("m.email_verification.modal.email_change") }}
          </a>
        </div>
        <div v-else class="mb-[114px] flex justify-between items-end">
          <div class="max-w-[278px] w-full">
            <label for="email" class="text-xs ml-4 mb-0 font-medium">
              {{ $t("m.email_verification.modal.email_input") }}
            </label>
            <field-text
              name="email"
              type="email"
              v-model="formEmail.email"
              :error="vEmail.email.$errors"
              :placeholder="$t('m.email_verification.modal.email_input_ph')"
            ></field-text>
          </div>
          <base-button outlined @click="changedEmail()">
            {{ $t("m.email_verification.modal.email_edit") }}
          </base-button>
        </div>
        <div class="flex justify-end gap-4">
          <base-button @click="tab = 1" outlined :disabled="isChangeEmail">
            {{ $t("m.email_verification.modal.prev") }}
          </base-button>
          <base-button @click="sendOtp()" :disabled="isChangeEmail">
            {{ $t("m.email_verification.modal.next") }}
          </base-button>
        </div>
      </div>
      <!-- form email -->
      <div v-else-if="tab == 3">
        <h3 class="text-xl font-bold mb-5">
          {{ $t("m.email_verification.modal.otp_title") }}
        </h3>
        <p class="text-xs mb-12 font-medium">
          {{ $t("m.email_verification.modal.otp_desc") }}
          {{ formEmail.email }}
          <!-- <span v-if="validTimeOtp > 0"> dalam waktu </span>
          <span class="font-bold" v-if="validTimeOtp > 0">
            {{ validTimeOtp }} detik
          </span> -->
        </p>
        <div class="mb-10">
          <div class="flex flex-row justify-between gap-x-2">
            <div class="max-w-[70px]">
              <field-text
                v-model="state.code1"
                :error="v$.code1.$errors"
                :showError="false"
                rounded="rounded-lg"
                classes="text-center"
                maxLength="1"
                name="code1"
                @paste="onPaste"
                @input="onInputOtp('idCode2', $event)"
                idInput="idCode1"
              />
            </div>
            <div class="max-w-[70px]">
              <field-text
                v-model="state.code2"
                :error="v$.code2.$errors"
                :showError="false"
                rounded="rounded-lg"
                classes="text-center"
                maxLength="1"
                name="code2"
                @paste="onPaste"
                @input="onInputOtp('idCode3', $event)"
                idInput="idCode2"
              />
            </div>
            <div class="max-w-[70px]">
              <field-text
                v-model="state.code3"
                :error="v$.code3.$errors"
                :showError="false"
                rounded="rounded-lg"
                classes="text-center"
                maxLength="1"
                name="code3"
                @paste="onPaste"
                @input="onInputOtp('idCode4', $event)"
                idInput="idCode3"
              />
            </div>
            <div class="max-w-[70px]">
              <field-text
                v-model="state.code4"
                :error="v$.code4.$errors"
                :showError="false"
                rounded="rounded-lg"
                classes="text-center"
                maxLength="1"
                name="code4"
                @paste="onPaste"
                @input="onInputOtp('idCode5', $event)"
                idInput="idCode4"
              />
            </div>
            <div class="max-w-[70px]">
              <field-text
                v-model="state.code5"
                :error="v$.code5.$errors"
                :showError="false"
                rounded="rounded-lg"
                classes="text-center"
                maxLength="1"
                name="code5"
                @paste="onPaste"
                @input="onInputOtp('idCode6', $event)"
                idInput="idCode5"
              />
            </div>
            <div class="max-w-[70px]">
              <field-text
                v-model="state.code6"
                :error="v$.code6.$errors"
                :showError="false"
                rounded="rounded-lg"
                classes="text-center"
                maxLength="1"
                name="code6"
                @paste="onPaste"
                idInput="idCode6"
              />
            </div>
          </div>
          <div
            v-if="
              v$.code1.$errors.length ||
              v$.code2.$errors.length ||
              v$.code3.$errors.length ||
              v$.code4.$errors.length ||
              v$.code5.$errors.length ||
              v$.code6.$errors.length
            "
            class="px-2 whitespace-normal text-[10px] leading-[11px] pt-3 text-error"
          >
            {{ $t("m.email_verification.modal.otp_validate") }}
          </div>
        </div>
        <div class="grid justify-center mb-[90px]">
          <p class="text-center font-medium text-xs leading-5 mb-0">
            {{ $t("m.email_verification.modal.otp_not_send") }} <br />
            <span v-if="validTimeOtp > 0">
              {{
                $t("m.email_verification.modal.otp_countdown", {
                  time: validTimeOtp,
                })
              }} </span
            >&nbsp;
            <a
              href="#send"
              @click="resendOtp"
              :class="validTimeOtp > 0 ? 'text-disabled' : 'text-primary '"
              class="font-bold hover:underline"
            >
              {{ $t("m.email_verification.modal.otp_resend") }}
            </a>
          </p>
        </div>
        <div class="flex justify-end gap-4">
          <base-button @click="submitOtp()">
            {{ $t("m.email_verification.modal.next") }}
          </base-button>
        </div>
      </div>
      <!-- form email -->
      <div v-else-if="tab == 4">
        <h3 class="text-xl font-bold mb-6">
          {{ $t("m.email_verification.modal.success_title") }}
        </h3>
        <div class="mb-9 flex justify-center">
          <img
            class="w-[160px] h-[160px] object-contain object-center"
            src="@/assets/images/components/success-check.png"
            alt="Success Change Email"
          />
        </div>
        <div class="grid justify-center mb-[50px]">
          <p class="text-center font-medium text-xs leading-5 mb-0">
            {{ $t("m.email_verification.modal.success_desc") }}
          </p>
        </div>
        <div class="flex justify-end gap-4">
          <base-button @click="done">
            {{ $t("m.email_verification.modal.finish") }}
          </base-button>
        </div>
      </div>
    </div>
  </base-modal>
</template>
<script setup>
import { useVuelidate } from "@vuelidate/core";
import { required, maxLength, email } from "@/utils/i18n-validators";
import {
  computed,
  getCurrentInstance,
  onMounted,
  reactive,
  ref,
  watch,
} from "vue";
import { useUserStore } from "@/stores/user";
import { useToast } from "vue-toastification";
import { useNotificationStore } from "@/stores/notification/notification";
import { useI18n } from "vue-i18n";
const toast = useToast();
const { proxy } = getCurrentInstance();
const { t } = useI18n();
const storeUser = useUserStore();
const notifStore = useNotificationStore();
const modal = ref(false);
const accountVerified = computed(() => {
  return storeUser.getUser("account_verified");
});
const accountEmail = computed(() => {
  return storeUser.getUser("email");
});
watch(accountVerified, async (val) => {
  if (val == false) {
    modal.value = true;
  }
});
const validTimeOtp = ref(0);
const tab = ref(1);
const information = reactive([
  {
    icon: "IconEmailAlert",
    title: "m.email_verification.modal.points.1.title",
    desc: "m.email_verification.modal.points.1.desc",
  },
  {
    icon: "IconPromo",
    title: "m.email_verification.modal.points.2.title",
    desc: "m.email_verification.modal.points.2.desc",
  },
  {
    icon: "IconTruckOutlined",
    title: "m.email_verification.modal.points.3.title",
    desc: "m.email_verification.modal.points.3.desc",
  },
]);

const isChangeEmail = ref(false);
const formEmail = reactive({
  email: "",
});
const rulesEmail = {
  email: {
    required,
    email,
  },
};
const vEmail = useVuelidate(rulesEmail, formEmail);
const logout = async () => {
  proxy.$component.setLoading(true);
  try {
    await storeUser
      .logout()
      .then(() => {
        window.location = "/auth/login";
      })
      .catch(() => {
        window.location = "/auth/login";
      });
    notifStore.clearHeader();
    proxy.$component.setLoading(false);
  } catch (error) {
    proxy.$component.setLoading(false);
    console.log(error);
  }
};
const resendOtp = async () => {
  if (validTimeOtp.value == 0) {
    if (accountEmail.value.toLowerCase() == formEmail.email.toLowerCase()) {
      sendOtp();
    } else {
      proxy.$component.setLoading(true);
      try {
        let payload = {
          newEmail: formEmail.email,
        };
        let res = await storeUser.changeEmailExistingAccount(payload);
        toast.success(res.message);
        validTimeOtp.value = parseInt(res.data.valid / 1000);
        isChangeEmail.value = false;
        proxy.$component.setLoading(false);
      } catch (error) {
        proxy.$component.setLoading(false);
      }
    }
  }
};
const sendOtp = async () => {
  proxy.$component.setLoading(true);
  try {
    let payload = {
      email: formEmail.email,
    };
    let res = await storeUser.sendOtpExistingAccount(payload);
    validTimeOtp.value = parseInt(res.data.valid / 1000);
    toast.success(res.message);
    tab.value = 3;
    proxy.$component.setLoading(false);
  } catch (error) {
    proxy.$component.setLoading(false);
  }
};

const changedEmail = async () => {
  let emailValid = await vEmail.value.$validate();
  if (emailValid) {
    if (accountEmail.value.toLowerCase() == formEmail.email.toLowerCase()) {
      isChangeEmail.value = false;
    } else {
      if (
        await proxy.$component.openConfirm({
          desc: t("m.email_verification.modal.next_with_email", {
            email: formEmail.email,
          }),
          options: {
            i18n: false,
          },
        })
      ) {
        proxy.$component.setLoading(true);
        try {
          let payload = {
            newEmail: formEmail.email,
          };
          let res = await storeUser.changeEmailExistingAccount(payload);
          toast.success(res.message);
          validTimeOtp.value = parseInt(res.data.valid / 1000);
          isChangeEmail.value = false;
          tab.value = 3;
          proxy.$component.setLoading(false);
        } catch (error) {
          proxy.$component.setLoading(false);
        }
      } else {
        formEmail.email = accountEmail.value;
        isChangeEmail.value = false;
      }
    }
  }
};
function onInputOtp(idx, val) {
  if (val) {
    document.getElementById(idx).focus();
  }
}

var state = reactive({
  code1: "",
  code2: "",
  code3: "",
  code4: "",
  code5: "",
  code6: "",
});
const allCodesFilled = computed(() => {
  return (
    !!state.code1 &&
    !!state.code2 &&
    !!state.code3 &&
    !!state.code4 &&
    !!state.code5 &&
    !!state.code6
  );
});
watch(allCodesFilled, (newVal) => {
  if (newVal) {
    submitOtp();
  }
});
const rules = {
  code1: {
    required,
    maxLength: maxLength(1),
  },
  code2: {
    required,
    maxLength: maxLength(1),
  },
  code3: {
    required,
    maxLength: maxLength(1),
  },
  code4: {
    required,
    maxLength: maxLength(1),
  },
  code5: {
    required,
    maxLength: maxLength(1),
  },
  code6: {
    required,
    maxLength: maxLength(1),
  },
};
const v$ = useVuelidate(rules, state);
const onPaste = () => {
  navigator.clipboard.readText().then((text) => {
    if (text) {
      let newArray = text.split("");
      for (let idx = 0; idx <= 5; idx++) {
        if (newArray.length > idx) {
          state[`code${idx + 1}`] = newArray[idx];
        } else {
          state[`code${idx + 1}`] = "";
        }
      }
    }
  });
};
const submitOtp = async () => {
  console.log("submitted");
  let otpValid = await v$.value.$validate();
  if (otpValid) {
    proxy.$component.setLoading(true);
    try {
      let otpCode = Object.values(state).join("");
      let payload = {
        otp: otpCode,
        reason: "penggantian email existing",
      };
      // if email changed send payload email
      if (accountEmail.value.toLowerCase() !== formEmail.email.toLowerCase()) {
        payload.newEmail = formEmail.email;
      }
      let res = await storeUser.submitOtpEmailExistingAccount(payload);
      toast.success(res.message);
      let user = storeUser.user;
      user.account_verified = true;
      user.email = formEmail.email;
      storeUser.setUser(user);
      tab.value = 4;
      proxy.$component.setLoading(false);
    } catch (error) {
      proxy.$component.setLoading(false);
    }
  }
};
onMounted(() => {
  if (accountVerified.value == false) {
    modal.value = true;
  }
  formEmail.email = accountEmail.value;
  setInterval(() => {
    if (validTimeOtp.value > 0) {
      validTimeOtp.value = validTimeOtp.value - 1;
    }
  }, 1000);
});
const done = () => {
  location.reload();
};
</script>
