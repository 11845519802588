<template>
  <div class="flex items-center gap-x-4">
    <base-button
      width="w-8"
      height="h-8"
      :disabled="props.modelValue <= 1"
      @click="setPage(props.modelValue - 1)"
    >
      <base-icon-mdi :path="mdiChevronLeft" size="24" />
    </base-button>
    <div class="text-[13px]/[20px] min-w-[50px] text-center text-black">
      {{ props.modelValue }}/{{ props.total }}
    </div>
    <base-button
      width="w-8"
      height="h-8"
      :disabled="props.modelValue >= props.total"
      @click="setPage(props.modelValue + 1)"
    >
      <base-icon-mdi :path="mdiChevronRight" size="24" />
    </base-button>
  </div>
</template>

<script setup>
import { mdiChevronRight, mdiChevronLeft } from "@mdi/js";
const props = defineProps({
  modelValue: {
    type: [Number, String],
    default: 1,
  },
  total: {
    type: [Number, String],
    default: 1,
  },
});
const emit = defineEmits(["update:modelValue"]);
const setPage = (val) => {
  emit("update:modelValue", val);
};
</script>
