export var dayjs = require("dayjs");
var isSameOrBefore = require("dayjs/plugin/isSameOrBefore");
dayjs.extend(isSameOrBefore);
var isBetween = require("dayjs/plugin/isBetween");
dayjs.extend(isBetween);
require("dayjs/locale/id");
require("dayjs/locale/en");
dayjs.locale("id");

// timezone
var timezone = require("dayjs/plugin/timezone"); // dependent on utc plugin
var utc = require("dayjs/plugin/utc");

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Asia/Jakarta");

export function formatDate(val) {
  return dayjs(val).format("YYYY-MM-DD");
}

export function formatDateUTC(val) {
  return dayjs(val).utc().format("YYYY-MM-DD");
}

export function formatDateSlash(val) {
  return dayjs(val).format("YYYY/MM/DD");
}

export function formatDateID(val) {
  return dayjs(val).format("DD-MM-YYYY");
}

export function formatDateID_UTC(val) {
  return dayjs(val).format("DD-MM-YYYY");
}

export function formatTime(val) {
  return dayjs(val).format("HH:mm");
}

export function formatTimeUTC(val) {
  return dayjs(val).utc().format("HH:mm");
}

export function formatDateTime(val) {
  return dayjs(val).format("YYYY-MM-DD HH:mm");
}

export function formatDateTimeUTC(val) {
  return dayjs(val).utc().format("YYYY-MM-DD HH:mm");
}

export function formatDateTimeID(val) {
  return dayjs(val).format("DD-MM-YYYY HH:mm");
}

export function formatDateSpell(val) {
  return dayjs(val).format("YYYY MMMM DD");
}

export function formatDateSpellID(val) {
  return dayjs(val).format("dddd, DD MMMM YYYY");
}

export function formatDateSpellIDShort(val) {
  return dayjs(val).format("DD MMMM YYYY");
}

export function formatDateSpellIDShortUTC(val) {
  return dayjs(val).utc().format("DD MMMM YYYY");
}

export function nowMonth() {
  const d = new Date();
  let month = d.getMonth();
  let year = d.getFullYear();
  return new Date(year, month + 1, 0).getDate();
}
