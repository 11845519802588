import request from "../../utils/request";
import { apiBase, apiTransaction } from "@/utils/env-global";

export function get(payload) {
  return request({
    url: `${apiTransaction}/v2/trsx-serv/shp/dashboard/shipments`,
    method: "GET",
    params: payload,
  });
}

export function getSummary(params) {
  return request({
    url: `${apiTransaction}/v2/trsx-serv/shp/dashboard/summary`,
    method: "GET",
    params: params,
  });
}

export function getTopOrder(params) {
  return request({
    url: `${apiTransaction}/v2/trsx-serv/shp/dashboard/top-orders`,
    method: "GET",
    params: params,
  });
}

export function checkVersion() {
  return request({
    url: `${apiBase}/v2/usmx-serv/content/current-version?platform=WEB&service=WEBAPP`,
    method: "GET",
  });
}
