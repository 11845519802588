<template>
  <teleport to="body">
    <div v-if="loading">
      <div
        class="fixed z-[99999] top-0 left-0 w-screen h-screen bg-gray-6 bg-opacity-5"
      >
        <div class="w-full h-full relative flex justify-center items-center">
          <div
            class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 z-20"
          >
            <div
              v-if="componentStore.loadingType == 'payment'"
              class="bg-white px-6 py-[30px] rounded-2xl w-[366px] shadow-xl"
            >
              <div class="text-xl mb-2 font-bold">Pembayaran</div>
              <div class="w-full flex justify-center">
                <img
                  class="w-[230px]"
                  src="@/assets/images/components/loading-animate.gif"
                  alt="loading animate"
                />
              </div>
              <div class="text-sm text-black">
                Mengalihkan anda ke halaman pembayaran..
              </div>
            </div>
            <div v-else>
              <img
                class="w-32"
                src="@/assets/images/logo/mologiz-gray.png"
                alt="mologiz"
              />
            </div>
          </div>
          <div
            v-if="componentStore.loadingType == 'normal'"
            :class="currentBorderColor"
            class="absolute w-40 h-40 transition-all rounded-full animate-spin border-l-2"
          ></div>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script setup>
import { useComponentStore } from "@/stores/component";
import { computed, ref, watch } from "vue";

const componentStore = useComponentStore();
const loading = computed(() => {
  return componentStore.loadingOverlay;
});
const currentBorderColor = ref("border-yellow");
const borderColors = ref([
  "border-yellow",
  "border-error",
  "border-success",
  "border-yellow-light",
  "border-primary",
]);
const randomBorder = () => {
  let random = Math.floor(Math.random() * borderColors.value.length);
  currentBorderColor.value = borderColors.value[random];
};
var runBorder = null;

watch(loading, (val) => {
  if (val) {
    runBorder = setInterval(() => {
      randomBorder();
    }, 1000);
  } else {
    clearInterval(runBorder);
  }
});
</script>
