import { DashboardLayout } from "@/layouts";
const dashboard = [
  {
    path: "/notification",
    component: DashboardLayout,
    meta: {
      title: "r_notification",
      icon: "IconNotification",
      group: "apps",
    },
    single: true,
    children: [
      {
        path: "",
        name: "notification",
        meta: {
          title: "r_notification",
          roles: ["userprofile:read"],
        },
        hidden: true,
        component: () =>
          import("@/views/shipper/notification/NotificationPage.vue"),
      },
    ],
  },
];

export default dashboard;
