import * as validators from "@vuelidate/validators";
import * as customValidators from "@/utils/custom-validator";
import i18n from "@/i18n";

// or import { createI18nMessage } from '@vuelidate/validators'
const { createI18nMessage } = validators;

// Create your i18n message instance. Used for vue-i18n@9
const withI18nMessage = createI18nMessage({ t: i18n.global.t.bind(i18n) });
// for vue-i18n@8
// const withI18nMessage = createI18nMessage({ t: i18n.t.bind(i18n) })

// wrap each validator.
export const required = withI18nMessage(validators.required);
export const requiredArray = withI18nMessage(customValidators.required);
// validators that expect a parameter should have `{ withArguments: true }` passed as a second parameter, to annotate they should be wrapped
export const minLength = withI18nMessage(validators.minLength, {
  withArguments: true,
});
export const url = withI18nMessage(validators.url);
export const email = withI18nMessage(validators.email);
// or you can provide the param at definition, statically
export const maxLength = withI18nMessage(validators.maxLength, {
  withArguments: true,
});
export const maxValue = withI18nMessage(validators.maxValue, {
  withArguments: true,
});
export const minValue = withI18nMessage(validators.minValue, {
  withArguments: true,
});
// for same as
export const sameAs = withI18nMessage(validators.sameAs, {
  withArguments: true,
});

// CUSTOM VALIDATOR
// for same as
export const password = withI18nMessage(customValidators.password);

export const requiredNumber = withI18nMessage(customValidators.requiredNumber);

export const requiredNumberDecimal = withI18nMessage(
  customValidators.requiredNumberDecimal
);

export const validNumber = withI18nMessage(customValidators.validNumber);

export const validPhone = withI18nMessage(customValidators.validPhone);

export const validString = withI18nMessage(customValidators.validString);
