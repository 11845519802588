const general = [
  {
    path: "/:pathMatch(.*)*",
    name: "not-found",
    meta: {
      title: "r_notfound",
    },
    hidden: true,
    component: () => import("@/views/general/NotFound.vue"),
  },
  {
    path: "/switch-app",
    name: "switch-app",
    meta: {
      title: "r_switch_app",
    },
    hidden: true,
    component: () => import("@/views/general/SwitchApp.vue"),
  },
];

export default general;
