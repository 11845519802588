import { DashboardLayout } from "@/layouts";
const guide = [
  {
    path: "/terms-and-conditions",
    component: DashboardLayout,
    meta: {
      title: "r_guide.guide",
      icon: "IconDashboard",
      group: "apps",
    },
    single: true,
    children: [
      {
        path: "",
        name: "tnc",
        meta: {
          title: "r_guide.guide",
          roles: ["dashboard:read"],
        },
        hidden: true,
        component: () => import("@/views/shipper/guide/TncPage.vue"),
      },
    ],
  },
];

export default guide;
