const componentFiles = require.context("./", true, /\.vue$/);

// you do not need `import component from './'`
// it will auto require all component
const components = componentFiles.keys().reduce((components, modulePath) => {
  const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, "$1");
  const value = componentFiles(modulePath);
  components[moduleName] = value.default;
  return components;
}, {});

// register component
const GlobalComponents = {
  install(Vue) {
    let keys = Object.keys(components);
    for (let index = 0; index < keys.length; index++) {
      Vue.component(keys[index], components[keys[index]]);
    }
  },
};

export default GlobalComponents;
