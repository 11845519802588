<script setup>
import { computed, ref } from "vue";
import { vOnClickOutside } from "@vueuse/components";
import ItemNotification from "./components/ItemNotification";
import EmptyNotification from "./components/EmptyNotification.vue";
import { useMainStore } from "@/stores/main";
import { useRouter } from "vue-router";
import { useNotificationStore } from "@/stores/notification/notification";
import { useUserStore } from "@/stores/user";
const storeMain = useMainStore();
const storeUser = useUserStore();
// STYLE FOR ACTIVE TAB
const styleActive = ref(
  "after:absolute after:w-full after:left-0 after:-bottom-0 after:h-0.5 after:border-b-2 after:border-black font-semibold"
);
const router = useRouter();
const tab = ref(1);
var show = ref(false);
const storeNotif = useNotificationStore();
const notifUnread = computed(() => {
  return storeNotif.itemsHeader.filter((x) => x.isRead == false);
});
const isVerified = computed(() => {
  if (storeUser.company_verified) return storeUser.company_verified;
  return false;
});
const toggleShow = () => {
  if (storeMain.screenSize == "sm") {
    router.push("/notification");
  } else {
    show.value = !show.value;
  }
};
const clickOutside = () => {
  if (show.value == true) {
    show.value = false;
  }
};
</script>

<template>
  <div v-on-click-outside="clickOutside">
    <base-badge @click="toggleShow" classIcon="text-opacity-[54%]">
      {{ storeNotif.total }}
    </base-badge>
    <Transition>
      <div
        v-if="show"
        class="absolute w-full max-md:right-0 max-w-[482px] top-14 h-auto right-2 shadow-3xl bg-white rounded-2xl"
      >
        <div class="p-4">
          <ul
            class="flex justify-center mb-0 gap-x-4 select-none gap-y-1 text-[13px]"
          >
            <li
              :class="tab == 1 ? styleActive : ''"
              class="cursor-pointer relative px-2"
              @click="tab = 1"
            >
              {{ $t("m.header_main.notification.not_read") }} ({{
                storeNotif.unread
              }})
            </li>
            <li
              :class="tab == 2 ? styleActive : ''"
              class="cursor-pointer relative px-2"
              @click="tab = 2"
            >
              {{ $t("m.header_main.notification.all") }} ({{
                storeNotif.total
              }})
            </li>
          </ul>
        </div>
        <div
          class="w-full overflow-hidden min-h-[348px] overflow-y-auto scrollY"
        >
          <TransitionGroup
            :name="tab == 1 ? 'slide-next' : 'slide-prev'"
            tag="div"
            class="flex relative"
          >
            <div class="w-full absolute left-0 top-0" v-if="tab == 1">
              <item-notification
                v-for="(x, i) in notifUnread"
                :key="i"
                :data="x"
              />
              <empty-notification
                :isVerified="isVerified"
                v-if="notifUnread.length == 0"
              />
            </div>
            <div class="w-full absolute left-0 top-0 h-full" v-if="tab == 2">
              <item-notification
                v-for="(x, i) in storeNotif.itemsHeader"
                :key="i"
                :data="x"
              />
              <empty-notification
                :isVerified="isVerified"
                v-if="storeNotif.itemsHeader.length == 0"
              />
            </div>
          </TransitionGroup>
        </div>
        <div class="flex justify-end p-4">
          <base-button
            outlined
            link
            to="/notification"
            color="primary"
            v-if="
              (tab == 1 && notifUnread.length > 0) ||
              (tab == 2 && storeNotif.itemsHeader.length > 0)
            "
            >{{ $t("m.header_main.notification.show_all") }}</base-button
          >
          <base-button
            outlined
            link
            to="/order"
            color="primary"
            v-else-if="isVerified"
            >{{ $t("m.header_main.notification.order") }}</base-button
          >
        </div>
      </div>
    </Transition>
  </div>
</template>
