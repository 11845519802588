<script>
import { ref, onMounted, computed } from "vue";
import { usePointerSwipe } from "@vueuse/core";
import LogoBlack from "@/components/modules/logo/LogoBlack.vue";
export default {
  components: { LogoBlack },
  props: {
    // DEFINE PROPS SLIDER CONTENT
    slider: {
      type: Array,
      default: function () {
        return [
          {
            image: require("@/assets/images/components/banner/auth/blank.jpg"),
            title: "Solusi Logistik",
            description:
              "Bergabunglah dengan MOLOGIZ dan temukan solusi logistik yang cepat, mudah, dan terpercaya untuk kebutuhan bisnis Anda.",
          },
        ];
      },
    },
    autoLoadTime: {
      type: Number,
      default: 3000,
    },
  },
  setup(props) {
    // CURRENT FOR SET CURRENT SHOW THE SLIDE
    const current = ref(0);
    // TRANSITION NAME DYNAMIC BY NAV SLIDE
    const transitionName = ref("fade");
    // DEFINE SLIDE THE SHOW
    const show = ref(false);
    // GET PROPS SLIDER
    const slides = computed(() => props.slider);
    // FUNCTION ON CLICK NAVIGATION SLIDE
    const slide = (dir) => {
      clearInterval(timeLoad.value);
      dir > current.value
        ? (transitionName.value = "slide-next")
        : (transitionName.value = "slide-prev");
      current.value = dir;
      setTimeout(() => {
        timeLoad.value = setInterval(next, props.autoLoadTime);
      }, 500);
    };
    function next() {
      clearInterval(timeLoad.value);
      transitionName.value = "slide-next";
      var len = slides.value.length - 1;
      current.value = current.value < len ? current.value + 1 : 0;
      setTimeout(() => {
        timeLoad.value = setInterval(next, props.autoLoadTime);
      }, 500);
    }
    function prev() {
      clearInterval(timeLoad.value);
      transitionName.value = "slide-prev";
      var len = slides.value.length - 1;
      current.value = current.value > 0 ? current.value - 1 : len;
      setTimeout(() => {
        timeLoad.value = setInterval(next, props.autoLoadTime);
      }, 500);
    }
    // NAVIGATION CAROUSEL SWIPE
    const elAuthCarousel = ref(null);
    const { isSwiping, direction } = usePointerSwipe(elAuthCarousel, {
      onSwipeEnd(e, direction) {
        switch (direction) {
          // IF SWIPE RIGHT SHOW PREV IMAGE
          case "RIGHT":
            prev();
            break;

          // IF SWIPE LEFT SHOW NEXT IMAGE
          case "LEFT":
            next();
            break;

          default:
            break;
        }
      },
    });
    const timeLoad = ref(setInterval(next, props.autoLoadTime));
    onMounted(() => {
      // SET TRUE THE VAR SHOW ON FIRST START
      show.value = true;
    });
    return {
      elAuthCarousel,
      current,
      transitionName,
      show,
      slides,
      slide,
      isSwiping,
      direction,
    };
  },
};
</script>

<template>
  <div class="overflow-hidden m-0">
    <!-- LOGO -->
    <div class="absolute left-7 2xl:left-[118px] top-7 2xl:top-14 z-10">
      <logo-black />
    </div>
    <!-- END LOGO -->
    <!-- NAVIGATION SWIPE -->
    <div
      ref="elAuthCarousel"
      class="absolute w-full h-full top-0 left-0 z-20"
    ></div>
    <!-- END NAVIGATION SWIPE -->
    <div
      class="w-full h-screen absolute top-0 left-0 flex items-center justify-center"
    >
      <transition-group tag="div" :name="transitionName" class="slides-group">
        <div v-if="show && slides[current]" :key="current" class="slide">
          <img
            draggable="false"
            class="w-full h-full object-cover object-center"
            data-v-a36df50a=""
            :src="slides[current].image"
          />
          <div
            class="absolute left-0 px-14 2xl:px-[90px] pb-28 2xl:pb-[220px] bottom-0 z-10 w-full"
          >
            <h2 class="font-bold text-3xl text-white">
              {{ slides[current].title }}
            </h2>
            <p class="font-normal text-base text-white">
              {{ slides[current].description }}
            </p>
          </div>
        </div>
      </transition-group>

      <div
        v-if="slider.length > 1"
        class="absolute z-20 flex gap-3 left-1/2 -translate-x-1/2 bottom-16 2xl:bottom-[120px]"
      >
        <div
          v-for="(x, i) in slider"
          :key="i"
          @click.stop="slide(i)"
          :class="current == i ? 'bg-white' : 'bg-disabled'"
          class="w-3 h-3 rounded-full cursor-pointer"
        ></div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import "./styles/index.scss";
</style>
