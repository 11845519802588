import request from "../../utils/request";
import { apiBase } from "@/utils/env-global";

export function get(params) {
  return request({
    url: `${apiBase}/v2/usmx-serv/content/onboarding`,
    method: "GET",
    params: params,
  });
}
