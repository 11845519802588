import { AuthLayout } from "@/layouts";
const shipper = [
  {
    path: "/auth",
    component: AuthLayout,
    meta: {
      title: "Authentication",
      group: "auth",
    },
    redirect: "/auth/login",
    single: false,
    children: [
      {
        path: "login",
        name: "auth-login",
        meta: {
          title: "r_auth.login",
        },
        hidden: true,
        component: () => import("@/views/auth/AuthLogin.vue"),
      },
      {
        path: "register",
        name: "auth-register",
        meta: {
          title: "r_auth.register",
        },
        hidden: true,
        component: () => import("@/views/auth/AuthRegister.vue"),
      },
      {
        path: "forgot-password",
        name: "auth-forgot-password",
        meta: {
          title: "r_auth.forgot_password",
        },
        hidden: true,
        component: () => import("@/views/auth/AuthForgotPassword.vue"),
      },
      {
        path: "forgot-password-success",
        name: "auth-forgot-password-success",
        meta: {
          title: "r_auth.forgot_password_success",
        },
        hidden: true,
        component: () => import("@/views/auth/AuthForgotPasswordSuccess.vue"),
      },
      {
        path: "reset-password",
        name: "auth-reset-password",
        meta: {
          title: "r_auth.reset_password",
        },
        hidden: true,
        component: () => import("@/views/auth/AuthResetPassword.vue"),
      },
      {
        path: "otp-verification",
        name: "auth-otp-verification",
        meta: {
          title: "r_auth.otp_verification",
        },
        hidden: true,
        component: () => import("@/views/auth/AuthOtpVerification.vue"),
      },
    ],
  },
];

export default shipper;
