<template>
  <router-link
    :to="redirectNotification(props.data.category, props.data.referenceID)"
  >
    <div @click="toLink" class="flex items-center hover:bg-gray-4 px-4">
      <div
        class="w-[30px] h-[30px] bg-primary flex items-center justify-center rounded-full"
      >
        <icon-svg
          :icon="$t(`${props.data.category.toLowerCase()}.icon`)"
          color="white"
          width="20"
          height="20"
        />
      </div>
      <div
        class="pl-4 pt-4 border-b border-gray-4"
        style="width: calc(100% - 30px)"
      >
        <div class="flex justify-between items-center">
          <base-chip :color="$t(`${props.data.category.toLowerCase()}.color`)">
            {{ $t(`${props.data.category.toLowerCase()}.text`) }}
          </base-chip>
          <div class="text-xs text-black-1">
            {{ formatDate(props.data.date) }}
          </div>
        </div>
        <div class="w-full pb-2 py-1.5">
          <div
            class="text-[13px] text-black line-clamp-1 font-semibold leading-5 mb-0.5"
          >
            {{ props.data.title }}
          </div>
          <div class="text-black-light line-clamp-2 h-[47px]">
            {{ props.data.body }}
          </div>
        </div>
      </div>
    </div>
  </router-link>
</template>

<script setup>
import { redirectNotification } from "@/utils";
import { formatDate } from "@/utils/dayjs";
const props = defineProps({
  data: {
    type: Object,
    default: function () {
      return {};
    },
  },
});
</script>
