import { defineStore } from "pinia";
import { get } from "@/api/master-data/onboarding";

export const useDataOnBoardingStore = defineStore("dataOnBoarding", {
  state: () => ({
    items: [],
  }),
  getters: {
    getItems: (state) => {
      // param : LOGIN,  REGISTER, FORGOTPASSWORD, RESETPASSWORD
      return (attr) => state.items.filter((x) => x.page == attr);
    },
  },
  actions: {
    get(payload) {
      return new Promise((resolve, reject) => {
        get(payload)
          .then((response) => {
            this.items = response.data;
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
});
