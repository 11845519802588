import { defineStore } from "pinia";
import {
  get,
  getSummary,
  getTopOrder,
  checkVersion,
} from "@/api/dashboard/dashboard";

export const useDashboardStore = defineStore("dashboard", {
  state: () => ({
    chart: null,
    summary: [],
    topOrder: [],
    version: null,
  }),
  getters: {},
  actions: {
    get(payload) {
      return new Promise((resolve, reject) => {
        get(payload)
          .then((response) => {
            this.chart = response.data;
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getSummary(payload) {
      return new Promise((resolve, reject) => {
        getSummary(payload)
          .then((response) => {
            this.summary = response.data;
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getTopOrder(payload) {
      return new Promise((resolve, reject) => {
        getTopOrder(payload)
          .then((response) => {
            this.topOrder = response.data;
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    checkVersion(payload) {
      return new Promise((resolve, reject) => {
        checkVersion(payload)
          .then((response) => {
            this.version = response.data.version;
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
});
