import { DashboardLayout } from "@/layouts";
const dashboard = [
  {
    path: "/",
    component: DashboardLayout,
    meta: {
      title: "r_dashboard.dashboard",
      icon: "IconDashboard",
      group: "apps",
    },
    single: true,
    children: [
      {
        path: "",
        name: "dashboard",
        meta: {
          title: "r_dashboard.dashboard",
          roles: ["dashboard:read"],
        },
        hidden: false,
        component: () =>
          import("@/views/shipper/dashboard/ShipperDashboard.vue"),
      },
    ],
  },
];

export default dashboard;
