import { createRouter, createWebHistory } from "vue-router";
import { publicRoute } from "./config.js";

export const router = createRouter({
  history: createWebHistory(),
  // history: createWebHistory(process.env.BASE_URL),
  routes: publicRoute,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 };
  },
});

router.beforeEach((to) => {
  // set default params for corporate.contract.ftl
  if (
    to.name === "corporate.contracts" &&
    Object.keys(to.query).includes("delivery_type") == false
  ) {
    to.query.delivery_type = "FTL";
  }
});

export function resetRouter() {
  // const getRoutes = router.getRoutes();
  // console.log(getRoutes);
  // console.log(publicRoute);
  // for (let i = 0; i < getRoutes.length; i++) {
  //   const element = getRoutes[i];
  //   router.removeRoute(element.name);
  // }
  // const newRouter = router;
  // router.matcher = newRouter.matcher; // reset router
}

// handling error first load
let path = location.pathname;
// const urlParams = new URLSearchParams(location.search);
// let newParams = [];
// for (const [key, value] of urlParams) {
//   newParams.push(`${key}=${value}`);
//   // if (Object.keys(newParams).includes(key)) {
//   //   if (typeof newParams[key] == "object") {
//   //     newParams[key] = [...newParams[key], ...[value]];
//   //   } else {
//   //     newParams[key] = [value];
//   //   }
//   // } else {
//   //   newParams[key] = value;
//   // }
// }
// let output = newParams.join("&");
// localStorage.setItem("redirect", path);
// localStorage.setItem("redirect_params", output);
router.addRoute({
  path: `${path}`,
  name: "manipulation",
  props: (route) => ({ query: route.query }),
  component: () => import("@/views/general/RedirectURL.vue"),
});

export default router;
