import axios from "axios";
// import { useUserStore as store } from "@/stores/user";
// import router from "../router";
import { token } from "./crypto-global";
import { decrypt } from "./crypto";
import { useToast } from "vue-toastification";
import { useUserStore } from "@/stores/user";
import { useMainStore } from "@/stores/main";
const toast = useToast();
// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 40000, // request timeout
});

// request interceptor
service.interceptors.request.use(
  (config) => {
    if (localStorage.getItem(token)) {
      const access = localStorage.getItem(token);
      config.headers["Authorization"] = `Bearer ${decrypt(access)}`;
    }
    return config;
  },
  (error) => {
    console.error(error);
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  (response) => {
    if (!response.data) {
      if (!response.data.data) response.data.data = response.data;
      return response;
    } else {
      const res = response.data;
      if (res.status === "error") {
        return Promise.reject(new Error(res.message || "Error"));
      }
      if (res.token === null) {
        return Promise.reject(new Error(res.message || "Error"));
      } else {
        if (typeof response.data === "object" && response.data !== null) {
          if (!response.data.data) response.data.data = response.data;
        }
        return response.data;
      }
    }
  },
  async (error) => {
    const storeUser = useUserStore();
    const storeMain = useMainStore();
    const getLang = (id, en) => {
      if (storeMain.currentLang == "id") return id;
      return en;
    };
    if (error) {
      var currentPath = window.location.pathname;
      var apiUrl = error.config.url ? error.config.url : "";
      var errorNumber = "#";
      if (apiUrl.includes("api-auth")) errorNumber = 1;
      if (apiUrl.includes("api-mdt")) errorNumber = 2;
      if (apiUrl.includes("api-pst")) errorNumber = 3;
      if (apiUrl.includes("api-trx")) errorNumber = 4;
      if (apiUrl.includes("api-trc")) errorNumber = 5;
      if (apiUrl.includes("api-msg")) errorNumber = 6;
      if (apiUrl.includes("api-wms")) errorNumber = 9;
      if (apiUrl.includes("/delivery")) errorNumber = 10;
      if (error.response) {
        const status = error.response.status;
        // console.log(error);
        if (status) {
          var data = error.response.data;
          if (data && data.size) {
            let file = new Blob([data], { type: "application/json" });
            await file
              .text()
              .then((value) => {
                data = JSON.parse(value);
              })
              .catch((error) => {
                console.log("Something went wrong" + error);
              });
          }

          let msg = getLang(
            "Terjadi kesalahan dengan server",
            "An error occurs with the server"
          );
          switch (status) {
            case 401:
              if (currentPath.includes("auth") == false) {
                storeUser
                  .logout()
                  .then(() => {
                    window.location = "/auth/login";
                  })
                  .catch(() => {
                    window.location = "/auth/login";
                  });
              } else {
                if (error.response.data) {
                  toast.warning(`${status} : ${error.response.data.message}`);
                  if (data.error) {
                    const errorResponse = data.error;
                    switch (errorResponse.name) {
                      case "TokenExpiredError":
                        return storeUser.logout().then(() => {
                          return service.request(error.config);
                        });
                      default:
                        break;
                    }
                    if (error.response.status === 403) {
                      storeUser.logout();
                    }
                  }
                }
              }
              break;
            case 403:
              if (error.response.data) {
                toast.warning(`${status} : ${error.response.data.message}`);
              } else {
                toast.warning(`${status} : ${error.message}`);
              }
              break;
            case 400:
              if (data.message) msg = data.message;
              toast.warning(`${status} : ${msg}`);
              break;
            case 406:
              if (data.message) msg = data.message;
              toast.warning(`${status} : ${msg}`);
              break;
            case 500:
              if (data.error) {
                if (data.error.message) msg = data.error.message;
              }
              if (data.message) {
                if (data.message.indexOf("E11000") > -1) {
                  msg = getLang(
                    "Data sudah ada(duplikat)",
                    "Data already exists (duplicate)"
                  );
                } else {
                  msg = data.message;
                }
              }
              toast.warning(`${status} : ${msg}`);
              break;
            // case 404:
            //   router.push({ name: "error-404" });
            //   break;
            default:
              if (data.message) msg = data.message;
              toast.error(`${status} : ${msg}`);
              break;
          }
        } else {
          if (error.code == "ERR_NETWORK") {
            toast.error(
              getLang(
                `Koneksi bermasalah mengakibatkan gagal terhubung ke server-${errorNumber}`,
                `Connection problems result in failure to connect to the server-${errorNumber}`
              ),
              {
                timeout: false,
              }
            );
          } else {
            toast.error(`${error.code} : ${error.message}`);
          }
        }
      } else if (error.code == "ERR_NETWORK") {
        toast.error(
          getLang(
            `Koneksi bermasalah mengakibatkan gagal terhubung ke server-${errorNumber}`,
            `Connection problems result in failure to connect to the server-${errorNumber}`
          ),
          {
            timeout: false,
          }
        );
      } else {
        toast.error(`${error.code} : ${error.message}`);
      }
    }
    return Promise.reject(error);
  }
);

export default service;
