export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["test"])},
  "validations_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["validations is language for vuelidate support."])},
  "validations": {
    "required": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The field ", _interpolate(_named("property")), " is required."])},
    "email": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The field ", _interpolate(_named("property")), " is not valid."])},
    "minLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The ", _interpolate(_named("property")), " field has a value of '", _interpolate(_named("model")), "', but it must have a min length of ", _interpolate(_named("min")), "."])}
  },
  "modules_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["m is language for every modules components."])},
  "m": {
    "header_auth": {
      "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
      "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])}
    },
    "footer_auth": {
      "version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VERSION"])},
      "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download Mologiz App"])}
    }
  },
  "layout_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["l is language for every layout."])},
  "l": {
    
  },
  "pages_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This language for every pages."])},
  "login": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login to your mologiz account to get started"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
    "email_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["name", "@", "email.com"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "password_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "remember": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remember Me"])},
    "forgot_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot Password ?"])},
    "login_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login Now"])}
  }
}