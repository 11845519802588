<template>
  <base-modal v-model="modal" max-width="1000px" zIndex="70">
    <div class="bg-white shadow-lg rounded-[36px] px-10 py-10 pb-6 relative">
      <div class="w-full mb-5 text-xl font-semibold">
        <h5
          class="max-w-[80%] text-ellipsis whitespace-nowrap mb-0 overflow-hidden"
        >
          {{
            itemFiles.length > 0
              ? itemFiles[currentFile].name
              : $t("m.preview_file.title")
          }}
        </h5>
      </div>
      <div class="w-full h-[70vh] max-h-[750px] mb-4 relative">
        <div class="w-full h-full" v-if="itemFiles.length">
          <embed
            v-if="itemFiles[currentFile].type == 'document'"
            :src="itemFiles[currentFile].file"
            width="100%"
            height="100%"
          />
          <div
            v-else
            class="img_producto_container"
            @mousemove="imgMove($event)"
            @mouseout="imgOut()"
            @mouseover="imgIn()"
          >
            <div
              :style="{
                backgroundImage: `url(${itemFiles[currentFile].file})`,
                transformOrigin: imgTransformOrigin,
                transform: imgTransform,
              }"
              class="img_producto"
            ></div>
          </div>
        </div>
      </div>
      <div
        :class="itemFiles.length > 1 ? 'justify-between' : 'justify-end'"
        class="flex flex-wrap gap-2"
      >
        <pagination-simple
          v-if="itemFiles.length > 1"
          v-model="currentPage"
          :total="itemFiles.length"
        ></pagination-simple>
        <div class="flex items-center gap-4">
          <base-button
            color="primary"
            iconLeftImage="IconDownload"
            colorIcon="primary"
            outlined
            @click="downloadFile(itemFiles[currentFile])"
          >
            {{ $t("m.preview_file.download") }}
          </base-button>
          <base-button
            v-if="itemFiles.length > 1"
            color="primary"
            colorIcon="primary"
            iconLeftImage="IconDownload"
            outlined
            @click="downloadAllFile"
          >
            {{ $t("m.preview_file.download_all") }}
          </base-button>
        </div>
      </div>
      <base-button
        @click="modal = false"
        icon
        type="submit"
        class="absolute top-6 max-md:top-4 right-6"
      >
        <base-icon-mdi :path="mdiClose" size="30" w="w-10" h="h-10" />
      </base-button>
    </div>
  </base-modal>
</template>
<script setup>
import { ref, watch, computed, getCurrentInstance } from "vue";
import { useFilesStore } from "@/stores/master-data/files";
import { mdiClose } from "@mdi/js";
import PaginationSimple from "../pagination/PaginationSimple.vue";
import * as fs from "file-saver";
import { useComponentStore } from "@/stores/component";

const { proxy } = getCurrentInstance();
const storeFiles = useFilesStore();
const componentStore = useComponentStore();
const fileViewerData = computed(() => {
  return componentStore.fileViewer;
});
// modal prop
const modal = ref(false);
const itemFiles = ref([]);
const currentFile = ref(0);
const currentPage = ref(1);
watch(currentPage, (val) => {
  currentFile.value = val - 1;
});
const generateFiles = async () => {
  proxy.$component.setLoading(true);
  let items = fileViewerData.value.items;
  let list = [];
  var i = 0;
  for (i = 0; i < items.length; i++) {
    try {
      let item = items[i];
      var itemFile = {};
      itemFile.path = item.path;
      var extension = item.path
        .substring(item.path.lastIndexOf(".") + 1)
        .toLowerCase();
      itemFile.type = extension == "pdf" ? "document" : "image";
      itemFile.name = item.name
        ? item.name
        : item.path.substring(item.path.lastIndexOf("/") + 1).toLowerCase();
      let res = await storeFiles.get(item.path);
      itemFile.file = URL.createObjectURL(res);
      list.push(itemFile);
    } catch (e) {
      console.log(e);
      // do something with your error
      // The loop will not break
    }
  }
  if (list.length > 0) {
    itemFiles.value = list;
    modal.value = true;
  }
  proxy.$component.setLoading(false);
};
watch(fileViewerData, (val) => {
  if (val.show) {
    generateFiles();
  } else {
    itemFiles.value = [];
    modal.value = val;
  }
});
const imgTransform = ref("scale(1)");
const imgTransformOrigin = ref("100% 100%");
const downloadFile = async (item) => {
  try {
    const res = await storeFiles.get(item.path);
    let url = URL.createObjectURL(res.data);
    setTimeout(() => {
      fs.saveAs(url, item.name);
    }, 1000);
  } catch (error) {
    console.log(error);
  }
};
const downloadAllFile = async () => {
  itemFiles.value.forEach((el) => {
    downloadFile(el);
  });
};
const imgMove = (e) => {
  const width =
    ((e.clientX - e.target.offsetLeft) / e.target.clientWidth) * 100;
  const height =
    ((e.clientY - e.target.offsetTop) / e.target.clientHeight) * 100;
  imgTransformOrigin.value = `${width}% ${height}%`;
};
const imgOut = () => {
  imgTransform.value = "scale(1)";
};
const imgIn = () => {
  imgTransform.value = "scale(1.5)";
};
</script>

<style scoped>
.img_producto_container {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}
.img_producto {
  position: absolute;
  cursor: crosshair;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  transition: transform 0.5s ease-out;
}
</style>
