import { defineStore } from "pinia";
import { get, edit } from "@/api/master-data/branch";

export const useBranchStore = defineStore("branch", {
  state: () => ({
    items: [],
  }),
  getters: {},
  actions: {
    get(params = {}) {
      return new Promise((resolve, reject) => {
        get(params)
          .then((response) => {
            this.items = response.data;
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    edit(payload) {
      return new Promise((resolve, reject) => {
        edit(payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
});
