<script setup>
import { useDashboardStore } from "@/stores/dashboard/dashboard";
import { computed, onMounted, ref } from "vue";
const storeDashboard = useDashboardStore();
// GET DATA FROM ENV
const namaApp = ref(process.env.VUE_APP_NAME);
const verisonApp = computed(() => {
  return storeDashboard.version;
});
const addressApp = ref(process.env.VUE_APP_ADDRESS);
const phoneApp = ref(process.env.VUE_APP_PHONE);
const emailApp = ref(process.env.VUE_APP_EMAIL);
const websiteApp = ref(process.env.VUE_APP_WEBSITE);
const logoApp = ref(require("@/assets/images/logo/mologiz-gray.png"));
onMounted(() => {
  if (!storeDashboard.version) {
    storeDashboard.checkVersion();
  }
});
</script>
<template>
  <base-image
    class="mb-0.5 hidden 2xl:block max-lg:mx-auto"
    width="105"
    height="21"
    :url="logoApp"
  />
  <base-image
    class="mb-0.5 max-lg:mx-auto 2xl:hidden"
    width="90"
    height="16"
    :url="logoApp"
  />
  <h2 class="hidden">{{ namaApp }}</h2>
  <p
    class="text-[8px] max-lg:text-center leading-[10px] 2xl:text-[10px] font-medium text-gray-2 2xl:leading-4 mb-0"
  >
    {{ $t("m.footer_auth.version") + " " + verisonApp }} <br />
    {{ addressApp }} <br />
    {{ phoneApp }} - {{ emailApp }} - {{ websiteApp }}
  </p>
</template>
