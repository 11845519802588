<template>
  <div class="flex items-center">
    <div
      class="w-10 h-10 flex justify-center items-center mr-[20px] max-md:mr-2"
    >
      <base-button
        height="h-[17px]"
        ref="targetSidebar"
        :shadow="false"
        icon
        color="white"
        width="w-[13px]"
        @click="toggleMenu"
      >
        <icon-svg icon="IconMenu" :width="13" :height="13" />
      </base-button>
    </div>
    <router-link to="/">
      <LogoBlack class="max-md:w-28" />
    </router-link>
  </div>
</template>

<script setup>
import { useMainStore } from "@/stores/main";
import LogoBlack from "../../logo/LogoBlack.vue";
const mainStore = useMainStore();
const toggleMenu = () => {
  mainStore.setSidebar(!mainStore.showSidebar);
};
</script>
