import request from "../../utils/request";
import { apiDelivery } from "@/utils/env-global";

const baseUrl = `${apiDelivery}/delivery/auth/v1`;

export function get(params) {
  return request({
    url: `${baseUrl}/my-branches`,
    method: "GET",
    params: params,
  });
}

export function edit(payload) {
  return request({
    url: `${baseUrl}/branches/${payload.id}`,
    method: "PUT",
    data: payload.data,
  });
}
