import { DashboardLayout, DefaultLayout } from "@/layouts";
const corporate = [
  {
    path: "/corporate",
    component: DashboardLayout,
    meta: {
      title: "r_corporate.corporate",
      icon: "IconCorporate",
      group: "apps",
    },
    single: false,
    hidden: false,
    children: [
      {
        path: "address",
        component: DefaultLayout,
        meta: {
          title: "r_corporate.master_address",
          group: "corporate",
        },
        redirect: "/corporate/address",
        hidden: false,
        children: [
          {
            path: "",
            name: "corporate.address",
            meta: {
              title: "r_corporate.master_address",
              roles: ["myaddress:read"],
            },
            hidden: true,
            component: () =>
              import("@/views/shipper/corporate/address/AddressPage.vue"),
          },
        ],
      },
      {
        path: "fleet",
        name: "r_corporate.fleet",
        meta: {
          title: "r_corporate.fleet",
          roles: ["fleet:read"],
        },
        hidden: false,
        component: () =>
          import("@/views/shipper/corporate/fleet/FleetPage.vue"),
      },
      {
        path: "products",
        name: "corporate.goods",
        meta: {
          title: "r_corporate.goods",
          roles: ["masterproduct:create", "masterproduct:read"],
        },
        hidden: false,
        component: () =>
          import("@/views/shipper/corporate/goods/GoodsPage.vue"),
      },
      {
        path: "contracts",
        component: DefaultLayout,
        meta: {
          title: "r_corporate.contract",
          group: "corporate",
        },
        redirect: "/corporate/contracts",
        hidden: false,
        children: [
          {
            path: "",
            name: "corporate.contracts",
            meta: {
              title: "r_corporate.contract_FTL",
              roles: ["shipperagreement:read"],
            },
            hidden: true,
            component: () =>
              import(
                "@/views/shipper/corporate/contract/ftl/list-ftl/ListContractFTL.vue"
              ),
          },
          {
            path: ":id",
            name: "corporate.contracts.detail",
            meta: {
              title: "r_corporate.contract_detail_FTL",
              roles: ["shipperagreement:read"],
            },
            hidden: true,
            component: () =>
              import(
                "@/views/shipper/corporate/contract/ftl/detail-ftl/DetailContractFTL.vue"
              ),
          },
        ],
      },
      // {
      //   path: "pool",
      //   name: "r_corporate.pool",
      //   meta: {
      //     title: "r_corporate.pool",
      //     roles: ["fleet:read"],
      //   },
      //   hidden: false,
      //   component: () => import("@/views/shipper/corporate/pool/PoolPage.vue"),
      // },
    ],
  },
];

export default corporate;
