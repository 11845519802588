// import { pinia } from "@/main";
// import { useMainStore } from "@/stores/main";
export default {
  currency(value, locale = "id-ID") {
    // const storeMain = useMainStore();
    // const currency = storeMain.currentLang;
    // locale = currency == "en" ? "en-US" : locale;
    if (typeof value !== "number") {
      return value;
    }
    var formatter = new Intl.NumberFormat(locale, {
      minimumFractionDigits: 0,
    });
    return formatter.format(value);
  },
  currencyToNumber(stringNumber, locale = "id-ID") {
    // const storeMain = useMainStore();
    // const currency = storeMain.currentLang;
    // locale = currency == "en" ? "en-US" : locale;
    var thousandSeparator = Intl.NumberFormat(locale)
      .format(11111)
      .replace(/\p{Number}/gu, "");
    var decimalSeparator = Intl.NumberFormat(locale)
      .format(1.1)
      .replace(/\p{Number}/gu, "");
    return parseFloat(
      stringNumber
        .replace(new RegExp("\\" + thousandSeparator, "g"), "")
        .replace(new RegExp("\\" + decimalSeparator), ".")
    );
  },
  currencyIDR(value) {
    return `Rp ${this.currency(value)}`;
  },
  currencyIDRToNumber(value) {
    let toNumb = value.slice(2, -1);
    return this.currencyToNumber(toNumb);
  },
  toCurrency(value) {
    let type = "id";
    switch (type) {
      case "id":
        return this.currencyIDR(value);

      default:
        return this.currencyIDR(value);
    }
  },
  toNumber(value) {
    let type = "id";
    switch (type) {
      case "id":
        return this.currencyToNumber(value, "id-ID");

      default:
        return this.currencyToNumber(value, "id-ID");
    }
  },
  cubicFormula(length, width, height) {
    if ((length > 0, width > 0, height > 0)) {
      let val = (length * width * height) / 1000000;
      val = String(Math.ceil(val * 100) / 100);
      return parseFloat(val);
    } else {
      return 0;
    }
  },
  maxFormula(a, b) {
    return Math.max(a, b);
  },
  volMetricFormula(length, width, height) {
    if ((length > 0, width > 0, height > 0)) {
      let val = (length * width * height) / 4000;
      val = String(Math.ceil(val * 100) / 100);
      return parseFloat(val);
    } else {
      return 0;
    }
  },
  toTrim(val) {
    return !!val && typeof val == "string" ? val.trim() : val;
  },
  removeNewLines(val) {
    return !!val && typeof val == "string"
      ? val.replace(/(\r\n|\n|\r)/gm, " ")
      : val;
  },
  clearText(val) {
    val = this.toTrim(val);
    return this.removeNewLines(val);
  },
  formatNumber(value, round = false, locale = "id-ID") {
    // const storeMain = useMainStore();
    // const currency = storeMain.currentLang;
    // locale = currency == "en" ? "en-US" : locale;
    if (typeof value !== "number") {
      return value;
    }
    let val = (value / 1).toFixed(10);
    if (round) val = String(Math.ceil(val * 100) / 100);
    return new Intl.NumberFormat(locale, {
      maximumFractionDigits: 10,
    }).format(val);
  },
  unFormatNumber(stringNumber, locale = "id-ID") {
    // const storeMain = useMainStore();
    // const currency = storeMain.currentLang;
    // locale = currency == "en" ? "en-US" : locale;
    stringNumber = stringNumber.toString();
    var thousandSeparator = Intl.NumberFormat(locale)
      .format(11111)
      .replace(/\p{Number}/gu, "");
    var decimalSeparator = Intl.NumberFormat(locale)
      .format(1.1)
      .replace(/\p{Number}/gu, "");
    // if (locale == "en-US")
    //   return parseFloat(
    //     stringNumber
    //       .replace(new RegExp("\\" + decimalSeparator), ".")
    //       .replace(new RegExp("\\" + thousandSeparator, "g"), "")
    //   );
    return parseFloat(
      stringNumber
        .replace(new RegExp("\\" + thousandSeparator, "g"), "")
        .replace(new RegExp("\\" + decimalSeparator), ".")
    );
  },
  prettyStringToJson(value) {
    if (value) {
      try {
        let obj = JSON.parse(value);
        return JSON.stringify(obj, null, 4);
      } catch (e) {
        return value;
      }
    }
    return "";
  },
};
