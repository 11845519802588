import { DashboardLayout } from "@/layouts";
const corporate = [
  {
    path: "/users",
    component: DashboardLayout,
    meta: {
      title: "r_users.list_users",
      icon: "IconUsers",
      group: "apps",
    },
    single: false,
    hidden: false,
    children: [
      {
        path: "staff",
        name: "users.staff",
        meta: {
          title: "r_users.list_staff",
          roles: ["staffmanagement:read"],
          // roles: ["shipperstaff:read"],
        },
        hidden: false,
        component: () => import("@/views/shipper/users/staff/StaffPage.vue"),
      },
      {
        path: "driver",
        name: "users.driver",
        meta: {
          title: "r_users.list_driver",
          roles: ["drivermanagement:read"],
        },
        hidden: false,
        component: () => import("@/views/shipper/users/driver/DriverPage.vue"),
      },
    ],
  },
];

export default corporate;
