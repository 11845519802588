<script setup>
import { computed, ref } from "vue";
import { vOnClickOutside } from "@vueuse/components";
import { useRouter } from "vue-router";
import { useUserStore } from "@/stores/user";
import { useNotificationStore } from "@/stores/notification/notification";
// import { oldShipperUrl } from "@/utils/env-global";
const notifStore = useNotificationStore();
// const { proxy } = getCurrentInstance();
const router = useRouter();
const userStore = useUserStore();
var show = ref(false);
const toggleShow = () => {
  show.value = !show.value;
};
// const company_category = computed(() => {
//   return userStore.getUser("company_category");
// });
const clickOutside = () => {
  if (show.value == true) {
    show.value = false;
  }
};
const logout = async () => {
  await userStore
    .logout()
    .then(() => {
      window.location = "/auth/login";
    })
    .catch(() => {
      window.location = "/auth/login";
    });
  notifStore.clearHeader();
};
// const switchApp = async () => {
//   proxy.$component.setLoading(true);
//   try {
//     let res = await userStore.reqSwitchApp();
//     localStorage.clear();
//     window.location = oldShipperUrl + `?token=${res.data}`;
//     proxy.$component.setLoading(false);
//   } catch (error) {
//     console.log(error);
//     proxy.$component.setLoading(false);
//   }
// };
const menus = computed(() => {
  let itemMenu = [
    {
      title: "m.header_main.account.profile",
      action: () => router.push({ name: "profile" }),
      icon: "IconAccount",
    },
    {
      title: "m.header_main.account.sign_out",
      action: logout,
      icon: "IconSignOut",
    },
  ];
  // if (company_category.value == "SHIPPER") {
  //   let newMenu = {
  //     title: "m.header_main.account.back_old_screen",
  //     action: switchApp,
  //     icon: "IconChangeDisplay",
  //   };
  //   itemMenu.splice(1, 0, newMenu);
  // }
  return itemMenu;
});
</script>

<template>
  <div v-on-click-outside="clickOutside">
    <div
      class="flex items-center gap-2 cursor-pointer"
      @click.prevent="toggleShow"
    >
      <base-avatar>
        <img
          v-if="userStore.getUser('avatar')"
          :src="userStore.getUser('avatar')"
          :alt="userStore.getUser('full_name')"
        />
        <icon-svg
          v-else
          color="white"
          icon="IconAccount"
          width="16"
          height="16"
        ></icon-svg>
      </base-avatar>
      <div class="max-md:hidden">
        <div
          class="text-sm max-w-[160px] text-ellipsis overflow-hidden whitespace-nowrap"
        >
          {{ userStore.getUser("full_name") }}
        </div>
        <div
          class="text-xs font-light max-w-[160px] text-ellipsis overflow-hidden whitespace-nowrap"
        >
          {{ userStore.getUser("company_name") }}
        </div>
      </div>
    </div>
    <div
      v-show="show"
      class="absolute top-14 right-2 p-[7px] py-[1px] shadow-3xl rounded-[14px] bg-white"
    >
      <ul class="mb-0">
        <li
          v-for="(menu, i) in menus"
          :key="i"
          @click.prevent="menu.action"
          class="flex last:border-none relative cursor-pointer border-b border-gray-1 font-medium after:absolute after:left-0 after:top-1/2 after:-translate-y-1/2 after:z-10 after:w-full after:rounded-full after:transition-all after:h-8 hover:after:bg-primary-thin items-center min-w-[136px] gap-3 text-sm px-2 py-3"
        >
          <icon-svg class="z-20" :width="16" :height="16" :icon="menu.icon" />
          <span class="z-20">{{ $t(menu.title) }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>
