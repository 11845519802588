<script setup>
import { ref } from "vue";

// GET IMAGE MOBILE APP
const androidImage = ref(
  require("@/assets/images/components/app/playstore.png")
);
const iosImage = ref(require("@/assets/images/components/app/appstore.svg"));
// GET LINK
const androidLink = ref(process.env.VUE_APP_ANDROID);
const iosLink = ref(process.env.VUE_APP_IOS);
</script>

<template>
  <h2
    class="text-xs/3 2xl:text-sm/5 mb-1 2xl:mb-2 max-lg:text-center text-[#000000de]"
  >
    {{ $t("m.footer_auth.download") }}
  </h2>
  <div class="flex flex-wrap max-lg:justify-center">
    <a
      :href="androidLink"
      target="_blank"
      class="hover:drop-shadow-md transition-shadow"
    >
      <base-image
        class="hidden 2xl:block"
        width="102"
        height="30"
        :url="androidImage"
      />
      <base-image
        class="2xl:hidden"
        width="80"
        height="24"
        :url="androidImage"
      />
    </a>
    <a
      :href="iosLink"
      target="_blank"
      class="hover:drop-shadow-md transition-shadow"
    >
      <base-image
        class="hidden 2xl:block"
        width="102"
        height="30"
        :url="iosImage"
      />
      <base-image class="2xl:hidden" width="80" height="24" :url="iosImage" />
    </a>
  </div>
</template>
