import { DashboardLayout, DefaultLayout } from "@/layouts";
const report = [
  {
    path: "/report",
    component: DashboardLayout,
    meta: {
      title: "r_report.report",
      icon: "IconReport",
      group: "apps",
    },
    single: false,
    hidden: false,
    children: [
      {
        path: "ar-invoice",
        component: DefaultLayout,
        meta: {
          title: "r_report.ar_invoice",
          group: "report",
        },
        redirect: "/report/ar-invoice/revision-history",
        hidden: false,
        children: [
          {
            path: "history-invoice",
            name: "report.ar_invoice.revision_history",
            meta: {
              title: "r_report.revision_history",
              roles: ["arinvoice:read"],
            },
            hidden: false,
            component: () =>
              import(
                "@/views/shipper/report/arinvoice/revision-history/RevisionHistory.vue"
              ),
          },
        ],
      },
    ],
  },
];

export default report;
