<script setup>
import AuthBanner from "@/components/modules/AuthBanner.vue";
import HeaderAuth from "@/components/modules/header/HeaderAuth.vue";
import FooterAuth from "@/components/modules/footer/FooterAuth.vue";
import { getMessaging, getToken } from "firebase/messaging";
import { useUserStore } from "@/stores/user";
import { onMounted } from "vue";
const userStore = useUserStore();
// fcm
const getTokenFcm = async () => {
  const messaging = getMessaging();
  getToken(messaging)
    .then((currentToken) => {
      if (currentToken) {
        // console.log(currentToken);
        // Send the token to your server and update the UI if necessary
        // console.log("Token is:", currentToken);
        userStore.setFcmToken(currentToken);
        // ...
      } else {
        // Show permission request UI
        console.log(
          "No registration token available. Request permission to generate one."
        );
        // ...
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
      // ...
    });
};
onMounted(() => {
  getTokenFcm();
});
</script>

<template>
  <div class="flex flex-row overflow-hidden">
    <div class="basis-[44.5%] max-md:hidden bg-primary">
      <auth-banner />
    </div>
    <div
      class="max-md:basis-full basis-[55.5%] bg-white relative w-full h-screen scroll-auto overflow-y-scroll whitespace-nowrap"
    >
      <div class="h-full grid content-between pt-7 2xl:pt-14">
        <header class="w-full px-12 max-sm:px-6 xl:px-16 2xl:px-[56px]">
          <header-auth />
        </header>
        <section
          class="w-full px-12 max-sm:px-6 xl:px-16 2xl:px-[56px] max-w-[690px] 2xl:max-w-[580px] mx-auto flex items-center py-4 justify-center"
        >
          <router-view />
        </section>
        <footer
          class="w-full px-12 max-sm:px-6 xl:px-16 2xl:px-[56px] border-t border-solid border-gray-3"
        >
          <footer-auth />
        </footer>
      </div>
    </div>
  </div>
</template>
