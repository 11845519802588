<template>
  <base-modal v-model="modal" persistent max-width="550px">
    <div class="p-8 bg-white shadow-lg rounded-[25px] relative max-md:p-5">
      <img
        src="@/assets/images/components/image-idle.png"
        alt="idle"
        class="w-full mb-6 max-md:mb-2"
      />
      <div class="text-xs font-medium text-center w-full mb-1">
        {{ $t("m.general.modal_idle.desc") }}
      </div>
      <div class="text-center w-full text-3xl font-bold mb-0">
        {{ timeOut }}
      </div>
      <!-- <base-button
        @click="modal = false"
        icon
        class="absolute top-6 max-md:top-4 right-6"
      >
        <base-icon-mdi :path="mdiClose" size="30" w="w-10" h="h-10" />
      </base-button> -->
    </div>
  </base-modal>
</template>
<script setup>
import { useNotificationStore } from "@/stores/notification/notification";
import { useUserStore } from "@/stores/user";
import { useIdle } from "@vueuse/core";
// import { mdiClose } from "@mdi/js";

import { ref, watch } from "vue";
const userStore = useUserStore();
const notifStore = useNotificationStore();
const { idle } = useIdle(60 * 60 * 1000);
const modal = ref(false);
const timeOut = ref(30);
watch(idle, (val) => {
  if (userStore.user !== null) {
    modal.value = val;
    if (val) {
      timeOut.value = 30;
      startCountDown();
    }
  }
});
const startCountDown = async () => {
  setTimeout(async () => {
    timeOut.value--;
    if (timeOut.value > 0 && idle.value) {
      startCountDown();
    } else if (timeOut.value <= 0 && idle.value && userStore.user !== null) {
      await logout();
    }
  }, 1000);
};
const logout = async () => {
  await userStore
    .logout()
    .then(() => {
      window.location = "/auth/login";
    })
    .catch(() => {
      window.location = "/auth/login";
    });
  notifStore.clearHeader();
};
</script>
