import request from "../../utils/request";
import { apiMessaging } from "@/utils/env-global";

const baseUrl = `${apiMessaging}/notification`;

export function get(payload) {
  return request({
    url: `${baseUrl}/get-notifications`,
    method: "GET",
    params: payload,
  });
}

export function show(payload) {
  return request({
    url: `${baseUrl}/detail-notification/${payload}`,
    method: "GET",
    params: {
      // posting_status: "corporate.cargo.01"
    },
  });
}

export function changeStatus(payload) {
  return request({
    url: `${baseUrl}/apply-status`,
    method: "POST",
    data: payload,
  });
}
